import { lazy } from "react";
import axios from "axios";

const api_url = process.env.REACT_APP_ESURVEY;
const projectId = process.env.REACT_APP_PROJECT_ID;

import eSurveyIconsvg from "../../../assets/wasfaty/esurveyicon.svg";
import esurveyHeadIcon from "../../../assets/wasfaty/esurveyHeadIcon.svg";
import camelCase from "lodash/camelCase";
import IntlService from "./IntlService";

const ESurveyForm = lazy(() => import("../../ESurvey/Surverys"));
const ESurveySub = lazy(() => import("../../ESurvey/Preview/responses"));
class Service {
  disable = true;
  esurveySideMenu = [];
  esurveyRoutes = [];
  isAvailable = true;

  getAllSurveys(projectId) {
    return axios.get(`${api_url}/api/surveysWithProjectId/${projectId}`);
  }

  getSurveyWithAnswers(surveyId, fillerId) {
    return axios.get(`${api_url}/api/surveys/answers/${surveyId}/${fillerId}`);
  }

  saveSurveyWithAnswers(surveyId, fillerId, data) {
    return axios.post(
      `${api_url}/api/surveys/answers/${surveyId}/${fillerId}`,
      data
    );
  }

  submitSurveyWithAnswers(surveyId, fillerId, data) {
    return axios.patch(
      `${api_url}/api/surveys/answers/${surveyId}/${fillerId}`,
      data
    );
  }

  // Responses
  getSurveyWithResponsesList(surveyId) {
    return axios.get(`${api_url}/api/surveys/responses/${surveyId}`);
  }

  deleteSurveyResponses(fillerId) {
    return axios.delete(`${api_url}/api/surveys/answers/${fillerId}`);
  }

  uploadToMinio(data) {
    return axios.post(`${api_url}/api/upload/files`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  download(originalImage) {
    return fetch(originalImage)
      .then((response) => response.blob())
      .then((response) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(response);
        link.download = originalImage.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  getListingPath = () => {
    let listLocation = window.location.pathname
      .split("/")
      .filter((i) => i)
      .slice(0, 2)
      .join("/");
    return listLocation;
  };

  async getSurveys() {
    const esurveyRoutes = [];
    const esurveySideMenu = [];
    if (this.isAvailable) {
      try {
        const {
          data: { data },
        } = await this.getAllSurveys(projectId);
        data?.forEach((item) => {
          esurveySideMenu.push({
            id: camelCase(item.title.en),
            title: item.title.en,
            titleAr: item.title.ab,
            navLink: `/${camelCase(item.title.en)}/${item._id}`,
            icon: () => (
              <img
                src={eSurveyIconsvg}
                style={{ marginRight: 10, marginLeft: 10 }}
                width="15"
              />
            ),
            isAuth: true,
          });

          esurveyRoutes.push(
            ...[
              {
                element: <ESurveySub key={Date.now()} />,
                path: `/${camelCase(item.title.en)}/:surveyId`,
                route: item.title.en,
                title: item.title.en,
                titleAr: item.title.ab,
                isAuth: true,
              },
              {
                element: <ESurveyForm key={Date.now()} />,
                path: `/${camelCase(item.title.en)}/:surveyId/:fillerId`,
                route: item.title.en,
                title: item.title.en,
                titleAr: item.title.ab,
                isAuth: true,
                isForm: true,
              },
              {
                element: <ESurveyForm key={Date.now()} />,
                path: `/${camelCase(item.title.en)}/:surveyId/form`,
                route: item.title.en,
                title: item.title.en,
                titleAr: item.title.ab,
                isForm: true,
                isAuth: true,
              },
            ]
          );
        });
      } catch (error) {
        console.log("Esurvey issue: ", error);
      }
    }
    this.esurveySideMenu = [
      {
        id: "nspection_campaigns",
        title: "Inspection Campaigns",
        icon: () => (
          <img
            src={esurveyHeadIcon}
            style={{
              marginLeft: IntlService.isRtl ? 10 : 0,
              marginRight: IntlService.isRtl ? 0 : 5,
            }}
            width="20"
          />
        ),
        children: esurveySideMenu,
        isAuth: true,
      },
    ];

    this.esurveyRoutes = esurveyRoutes;

    return {
      esurveySideMenu: this.esurveySideMenu,
      esurveyRoutes,
    };
  }
}
const ESurveyService = new Service();
export default ESurveyService;
