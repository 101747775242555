// ** React Imports
import { Fragment, lazy } from "react";

//import ChartsRoutes from './Charts'
import ListRoutes from "./ListRoutes";
import AddRoutes from "./AddRoutes";
import AuthenticationRoutes from "./Authentication";
// DetailsRoute
import DetailsRouter from "./DetailsRouter";
// ** Layouts
import BlankLayout from "@layouts/BlankLayout";
import VerticalLayout from "@src/layouts/VerticalLayout";
import HorizontalLayout from "@src/layouts/HorizontalLayout";
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper";

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute";
import PrivateRoute from "@components/routes/PrivateRoute";
import RoutingService from "../../views/wasfaty/services/RoutingService";

// ** Utils
import { isObjEmpty } from "@utils";
const ObligationGraph = lazy(() =>
  import("../../views/pages/Graph/obligation")
);

const Users = lazy(() => import("../../views/pages/Add/User/User"));
const Multi_Sites = lazy(() =>
  import("../../views/pages/Add/Multi-Sites Form/index")
);

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
};

// ** Document title
const TemplateTitle = "%s - Vuexy React Admin Template";

// ** Default Route
const DefaultRoute = "/Outbreak-717";

const Empty = () => {
  return null;
};

// ** Merge Routes
let Routes = [
  ...AuthenticationRoutes,
  ...ListRoutes,
  ...AddRoutes,
  ...DetailsRouter,
];

// based on defaultFirstRouteName this will showw the page
const defaultRoutes = {
  user: Users,
  // "pharmacy audit": PharmacyAuditGraph,
  // "phc checklist": PhcGraph,
  // pharmacy: Pharmacy,
  obligation: ObligationGraph,
  // irs: Irs,
  // phc: Phc,
  // empty: Empty,
  // nupco: Nupco,
  users: Users,
  // nupco_list: NupcoList,
};

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment;

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = async (layout) => {
  const defaultLayout = layout || "vertical";
  const layouts = ["vertical", "horizontal", "blank"];
  await RoutingService.init(Routes);
  Routes = RoutingService.routes;
  const AllRoutes = [];

  layouts.forEach((layoutItem, i) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);
    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes,
      key: { i },
    });
  });
  return AllRoutes;
};

export { DefaultRoute, TemplateTitle, Routes, getRoutes, defaultRoutes };
