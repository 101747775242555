import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";

class EventTypeService extends Resources {
  route = "eventType";
  routes = {
    find: "?isPagination=true",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };

  allSites = "eventType";
  getSite = "eventType";

  constructor() {
    super(arguments);
  }

  getEventTypeById(id) {
    return SC.getCall({
      url: this.getSite + `/show/${id}`,
    });
  }
  getAllSites(params) {
    return SC.getCall({
      url: this.allSites,
      params,
    });
  }

  getSitesByType(params) {
    return SC.getCall({
      url: this.allSitesByType,
      params,
    });
  }

  getHeaders() {
    return [{ title: "Name", dataIndex: "name" }];
  }
}

export default Singleton(EventTypeService);
