import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import { SC } from "../../../wasfaty/Api/serverCall";
import toast from "react-hot-toast";
import { NavigationService } from "../../../wasfaty/services";

function NotificationsAdd() {
  const { id } = useParams();

  const [name, set_notification] = useState("");
  const [isEdit, set_isEdit] = useState(false);
  useEffect(() => {
    if (id) {
      init();
    }
  }, [id]);

  const init = () => {
    set_isEdit(true);
    SC.getCall({ url: `notification/show/${id}` }).then((res) => {
      const data = res?.data?.data.name;
      if (data) {
        set_notification(data);
      }
    });
  };
  const submit = (e) => {
    e.preventDefault();

    let payload = { name: name };

    let servicePromise = null;
    if (!isEdit) {
      servicePromise = SC.postCall({
        url: "notification/create",
        data: payload,
      });
    } else {
      servicePromise = SC.putCall({
        url: `notification/update/${id}`,
        data: payload,
      });
    }
    servicePromise.then((res) => {
      if (res.status === 200 && res.data) {
        !isEdit && set_notification("");
        toast.success(res.data.data);

        NavigationService.Navigation("/Notifications");
      } else {
        throw res;
      }
    });
  };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form onSubmit={submit}>
            <Row>
              <Col className="mb-1" md="12" sm="12">
                <Label>Notification</Label>
                <Input
                  value={name}
                  onChange={(e) => set_notification(e.target.value)}
                  required
                />
              </Col>
            </Row>
            <div className="d-flex flex-column align-items-end">
              <Button color="primary" submit>
                {isEdit ? "Update" : "Submit"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
}

export default NotificationsAdd;
