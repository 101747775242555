// ** React Imports
import { Fragment } from "react";

// ** Third Party Components
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.sa";

// ** Reactstrap Components
import { InputGroup, InputGroupText, Label } from "reactstrap";
import InputMask from "react-input-mask";
const options = {
  phone: true,
  phoneRegionCode: "sa",
  numeral: true,
  numeralDecimalScale: 8,
};

const PhoneMask = ({ value, onChange, disabled }) => {
  return (
    <Fragment>
      <div className="input-group" dir="ltr">
        <div className="input-group-prepend">
          <span className="input-group-text"> SA (+966)</span>
        </div>
        <Input
          onChange={onChange}
          value={value}
          dir="ltr"
          disabled={disabled}
        />
      </div>
    </Fragment>
  );
};

export default PhoneMask;

const Input = (props) => (
  <InputMask
    mask="99 999 9999"
    value={props.value}
    onChange={props.onChange}
    disabled={props.disabled}
    dir="ltr"
  >
    {(inputProps) => (
      <input {...inputProps} type="tel" className="form-control" dir="ltr" />
    )}
  </InputMask>
);
