import { lazy } from "react";
import DepartmentDetails from "../../views/pages/Show/DepartmentDetails";
import DiseaseDetails from "../../views/pages/Show/DiseaseDetails";
import MainDashboard from "../../views/pages/Graph/MainDashboard";
const UserDetails = lazy(() => import("../../views/pages/Show/UserDetails"));

const RegionDetails = lazy(() =>
  import("../../views/pages/Show/RegionDetails")
);
const SiteDetails = lazy(() => import("../../views/pages/Show/SiteDetails"));
const ModuleDetails = lazy(() =>
  import("../../views/pages/Show/ModuleDetails")
);

// %templateImport%
const OutbreakInformation = lazy(() =>
  import("../../views/pages/Show/OutbreakInformation")
);

const DetailsRouter = [
  {
    element: <UserDetails />,
    path: "/Users/Details/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
  },
  {
    element: <MainDashboard />,
    path: "/Dashboard",
    route: "Dashboard",
    slug: "Dashboard",
    title: "Dashboard",
  },
  {
    element: <DepartmentDetails />,
    path: "/Departments/Details/:id",
    route: "Departments",
    slug: "Departments",
    title: "Departments",
  },
  {
    element: <RegionDetails />,
    path: "/Regions/Details/:id",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
  },
  {
    element: <DiseaseDetails />,
    path: "/Diseases/Details/:id",
    route: "Diseases",
    slug: "Diseases",
    title: "Diseases",
  },
  {
    element: <SiteDetails />,
    path: "/Sites/Details/:id",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
  },

  {
    element: <ModuleDetails />,
    path: "/Modules/Details/:id",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },
  {
    element: <OutbreakInformation />,
    path: "/Outbreak-717/Details/:id",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
  },
  {
    element: <OutbreakInformation key={"tasks-route"} />,
    path: "/Outbreak-717/Task/:task_id",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
  },
  // %template%
];
export default DetailsRouter;
