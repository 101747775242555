import { Singleton } from "../Resource";
import get from "lodash/get";
import { getUserData } from "@utils";
import navigation from "@src/navigation/vertical";
import { Navigate } from "react-router-dom";
import { AuthClass } from "./AuthService";
import ESurveyService from "./ESurveyService";
import ModuleService from "./ModuleService";

class Service extends AuthClass {
  defaultFirstRouteName = "";
  routes = [];
  sideMenu = [];
  eSurveyItems = [];
  permissions = [];
  constructor() {
    super(arguments);
  }

  async init(routes) {
    this.routes = this.getAvailableRoutes(routes);
    this.sideMenu = this.updateSideMenu([...navigation]);
    let userData = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      this.permissions = userData.roles[0]?.permissions?.map(
        (item) => item?.name || ""
      );
    }
    // Add default root route (/) component in the routes
    this.addFirstRedirectRoute();

    if (!ESurveyService.disable) {
      // Survey routes pushing in initial data
      const { esurveySideMenu, esurveyRoutes } =
        await ESurveyService.getSurveys();

      this.routes.push(...esurveyRoutes);
      this.sideMenu.push(...esurveySideMenu);
    }
  }

  get allFormRoutes() {
    return this.routes.filter((r) => r.isForm).map((r) => r.path);
  }

  addFirstRedirectRoute() {
    // Get url of first child from the side menu
    let parentRoute = get(this.sideMenu, "0.children", []).filter(
      ({ isDisable }) => !isDisable
    );
    let route = get(parentRoute, "0.navLink", get(this.sideMenu, "0.navLink"));

    if (this.routes?.length && route) {
      this.routes.push({
        element: <Navigate to={route} />,
        path: "/",
      });
    }
  }

  getAvailableRoutes(routes) {
    return routes.filter((r) => {
      //  Admin can have access to all routes

      if (r.onlyFor) {
        return r.onlyFor?.includes?.(this.role);
      }

      if (
        this.isAdmin ||
        this.isHQPHOC ||
        this.isHQDepartment ||
        this.isHQAudit ||
        this.isRegionPHOC ||
        this.isRegionDepartment ||
        this.isRegionAudit
      ) {
        return true;
      }

      let check = this.availableModules.includes(r.slug?.toLowerCase());
      //check dashboard routes
      let dashboardRoute = r.path.split("/").includes("Dashboard");
      if (dashboardRoute && (this.isPharmacist || this.isAgent)) {
        check = false;
      }

      if (this.isModerator && r.isForm) {
        check = false;
      }

      // return check || r.isAuth;
      return true;
    });
  }

  isAvailableInRoutes(routeName) {
    // Support all routes including singular and plural
    let menuTitle = [
      routeName,
      routeName + "s",
      routeName?.substr(0, routeName.length - 1),
    ];
    if (routeName == "settings") return true;
    if (routeName == "forms") return true;
    if (routeName == "hajj") return true;
    // Only available modules are visiable in the side menu
    return (
      this.availableModules.includes(menuTitle[0]) ||
      this.availableModules.includes(menuTitle[1]) ||
      this.availableModules.includes(menuTitle[2])
    );
  }

  updateSettingRoutes(menuData) {
    let SettingRoute = [];
    if (menuData && !this.isAdmin) {
      let settings = get(
        menuData.filter(({ id }, i) => id === "settings"),
        "0"
      );

      if (settings) {
        SettingRoute = settings.children.filter((sroute) =>
          this.availableModules.includes(sroute.title.toLowerCase())
        );
      }

      if (SettingRoute?.length) {
        settings.children = SettingRoute;
      } else {
        menuData = menuData.filter(({ id }, i) => id !== "settings");
      }
    }
    return menuData;
  }

  updateHajjRoutes(menuData) {
    let HajjRoutes = [];
    if (menuData && !this.isAdmin) {
      let hajj = get(
        menuData.filter(({ id }, i) => id === "hajj"),
        "0"
      );

      if (hajj) {
        HajjRoutes = hajj.children;
      }

      if (HajjRoutes?.length) {
        hajj.children = HajjRoutes;
      } else {
        menuData = menuData.filter(({ id }, i) => id !== "hajj");
      }
    }
    return menuData;
  }

  // removeModuleRoutes(menuData) {
  //   const selectedRole = this.user?.roles?.find(
  //     (item) => this.user.type === item.name.toLowerCase()
  //   );

  //   return menuData.filter((menu) => {
  //     // All routes are accessable by the admin
  //     // Side menu will show all menu for admin
  //     if (menu.onlyFor) {
  //       return menu.onlyFor?.includes?.(this.role);
  //     }

  //     if (this.isAdmin) {
  //       return true;
  //     }
  //     if (menu.children) {
  //       return menu.children.some((child) => {
  //         if (child.permissions) {
  //           return child.permissions.some((permission) => {
  //             if (selectedRole) {
  //               return selectedRole.permissions
  //                 ?.map((item) => item?.name || "")
  //                 .includes(permission);
  //             }
  //             return false;
  //           });
  //         }
  //         return false;
  //       });
  //     }
  //     // Remove all modules which are not available for the user
  //     let routeName = menu?.slug?.toLowerCase().replace(" form", ""); //TODO: need to change
  //     return this.isAvailableInRoutes(routeName) || menu.isAuth;
  //   });
  // }

  removeModuleRoutes(menuData) {
    return menuData.filter((menu) => {
      // Check if menu has children
      if (this.isAdmin) {
        return true;
      }
      if (menu.children) {
        const selectedRole = this.user?.roles?.find(
          (item) => this.user.type === item.name.toLowerCase()
        );

        // Check if selectedRole and permissions exist
        if (selectedRole && selectedRole.permissions) {
          menu.children = menu.children.filter((child) => {
            // Check if child has permissions and if selectedRole has any of those permissions
            if (child.permissions) {
              return child.permissions.some((permission) =>
                selectedRole.permissions.some(
                  (rolePermission) => rolePermission.name === permission
                )
              );
            }
            return false; // If child has no permissions, remove it
          });

          // If menu has no children left after filtering, remove the menu
          if (menu.children.length === 0) {
            return false;
          }
        } else {
          // If selectedRole or permissions are missing, remove the menu
          return false;
        }
      } else {
        // If menu has no children, remove the menu
        return false;
      }

      // Keep the menu if it still has children with permissions
      return true;
    });
  }

  removeChildRoutes(menuData) {
    return menuData.map((route) => {
      if (this.isAdmin) {
        return route;
      }
      // Remove all child routes which are not avalilable for the user
      if (route.children) {
        route.children = route.children?.filter((child) => {
          if (this.isModerator) {
            if (child?.title === "Form") {
              return false;
            }
          }
          if (
            this.user?.roles[0]?.permissions
              ?.map((item) => item?.name || "")
              .some((ele) => child.permissions.includes(ele))
          ) {
            return true;
          } else {
            return false;
          }
          if (!this.availableModules.includes(child?.slug?.toLowerCase())) {
            // if (this.isAgent) {
            //   //  Agent can't access to any of the dashbords
            //   if (child?.title === "Dashboard") {
            //     return false;
            //   }
            // }

            // if (
            //   this.isHQPHOC ||
            //   this.isHQDepartment ||
            //   this.isHQAudit ||
            //   this.isRegionPHOC ||
            //   this.isRegionDepartment ||
            //   this.isRegionAudit
            // ) {
            //   if (
            //     child?.title?.toLowerCase() !== "outbreak information form" ||
            //     child?.title?.toLowerCase() !== "multi-sites outbreak"
            //   ) {
            //     return true;
            //   }
            // }
            return false;
          }

          return true;
        });
      }

      return route;
    });
  }

  getNameByRoute() {
    let slug = location.pathname.split("/")[1];
    const route =
      this.routes.filter((item) => item?.path.includes(slug))?.[0] || {};
    let name = ModuleService.getNameBySlug(slug);
    if (name) {
      route.moduleName = name;
    }
    return route;
  }

  updateNameBySlug(menuData) {
    return menuData.map((menu) => {
      menu.title = ModuleService.getNameBySlug(menu.slug) || menu.title;
      menu.text = ModuleService.getNameBySlug(menu.slug) || menu.text;
      if (menu?.children?.length) {
        this.updateNameBySlug(menu.children);
      }
      return menu;
    });
  }

  /**
   * Update the menu data based on user role and accessablity to the available module
   */
  updateSideMenu(menuData) {
    // Removed Parent Module
    menuData = this.removeModuleRoutes(menuData);
    const selectedRole = this.user?.roles?.find(
      (item) => this.user.type === item.name.toLowerCase()
    );
    console.log("selected", selectedRole, menuData);
    //setting routes that includes setting modules for all roles
    // menuData = this.updateSettingRoutes(menuData);

    // Removed Child route
    // menuData = this.removeChildRoutes(menuData);

    // Update name according to module name
    menuData = this.updateNameBySlug(menuData);
    return menuData;
  }
}

const RoutingService = new Service();
export default RoutingService;
