// ** React Imports
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Avatar from "@components/avatar";
//import avatarImg from "@src/assets/images/portrait/small/avatar-s-20.jpg";
import User from "../../../../../assets/images/user.png";
// ** Icons Imports
import { Disc, X, Circle } from "react-feather";
// ** Config
//import themeConfig from "@configs/themeConfig";

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils";
// import Logo from "../../../../../assets/images/brandlogoWhite.svg";
import Logo from "../../../../../assets/wasfaty/brandlogo.svg";
// import Favicon from "../../../../../assets/images/mohsmallicon.png";
import LogoutDropdown from "../../../../../views/wasfaty/Helper/LogoutDropdown";
import colpsedMOHIcon from "../../../../../assets/wasfaty/MOH-small.svg";
import Header from "../../../../../assets/images/moh-logo.png";
import "./style.scss";
const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    //setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props;

  // ** Vars
  const user = getUserData();
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);
  const navigate = useNavigate();
  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      );
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      );
    }
  };

  //test

  return (
    <div style={{ width: "100%" }} className="bg-nav-header">
      <div
        style={{
          display: "inline-flex",
          // backgroundColor: "#1F2854",
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
          marginTop: 20,
          marginRight: -10,
        }}
        onClick={() => navigate("/")}
      >
        {!menuCollapsed || menuHover ? (
          <>
            <img
              src={Header}
              className="img-fluid navbar-logo"
              alt="logo"
              style={{ width: "180px", marginTop: "20px" }}
            />
            {/* <Toggler /> */}
          </>
        ) : (
          <img src={colpsedMOHIcon} width="50" />
        )}
      </div>

      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink to={"/"} className="navbar-brand m-0">
            <span className="brand-logo"></span>
          </NavLink>
        </li>
        <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer"></div>
        </li>
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
