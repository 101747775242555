import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { SC } from "../../../wasfaty/Api/serverCall";
import toast from "react-hot-toast";
import { NavigationService } from "../../../wasfaty/services";
import Select from "react-select";
import { selectThemeColors } from "@utils";
import { FieldValidation } from "../../../AuthScreens/RenderFeedback";
import Validation from "../../../ESurvey/Surverys/helpers/Validation";
import { FormattedMessage } from "react-intl";

export const ValidationHandlerMain = ({
  isValid,
  message = `"This field is required!"`,
}) => {
  return (
    <>
      <Input type="text" hidden invalid={!isValid} />
      <FormFeedback>
        <FormattedMessage id={message} defaultMessage={message} />
      </FormFeedback>
    </>
  );
};
let initial = { name: "", health_directorate: null };
function PublicEventsAdd() {
  const { id } = useParams();

  const [data, set_data] = useState(initial);
  const [isEdit, set_isEdit] = useState(false);
  const [isValidationError, set_isValidationError] = useState(false);

  isValidationError;
  const [healthdirectorate_op, set_healthdirectorate_op] = useState([]);
  useEffect(() => {
    if (id) {
      init();
    }
    getHealthDirectorate();
  }, [id]);

  const getHealthDirectorate = () => {
    SC.getCall({ url: `regions` }).then((res) => {
      if (data) {
        let op = res?.data?.map((it) => {
          return { ...it, label: it.name, value: it._id };
        });
        set_healthdirectorate_op(op);
      }
    });
  };

  const init = () => {
    set_isEdit(true);
    SC.getCall({ url: `publicEvent/show/${id}` }).then((res) => {
      let rawdata = res?.data?.data;
      const data = {
        name: rawdata.name,
        health_directorate: {
          value: rawdata.health_directorate._id,
          label: rawdata.health_directorate.name,
        },
      };
      if (data) {
        set_data(data);
      }
    });
  };
  const submit = (e) => {
    e.preventDefault();

    if (data.name && data.health_directorate) {
      let payload = {
        name: data.name,
        directorate_id: data.health_directorate?.value,
      };

      let servicePromise = null;
      if (!isEdit) {
        servicePromise = SC.postCall({
          url: "publicEvent/create",
          data: payload,
        });
      } else {
        servicePromise = SC.putCall({
          url: `publicEvent/update/${id}`,
          data: payload,
        });
      }
      servicePromise.then((res) => {
        if (res.status === 200 && res.data) {
          !isEdit && set_data(initial);
          toast.success(res.data.data);

          NavigationService.Navigation("/public-Events");
        } else {
          throw res;
        }
      });
    } else {
      set_isValidationError(true);
      toast.error("Plase fill all required fields!");
    }
  };

  const handleChange = (label, value) => {
    set_data((prev) => ({ ...prev, [label]: value }));
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form onSubmit={submit}>
            <Row>
              <Col className="mb-1" md="12" sm="12">
                <Label>Event Name</Label>
                <Input
                  value={data.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {isValidationError && (
                  <ValidationHandlerMain isValid={!!data.name} />
                )}
              </Col>
              <Col className="mb-1" md="12" sm="12">
                <Label>Health Directorate</Label>
                <Select
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  options={healthdirectorate_op}
                  placeholder="Select"
                  value={data.health_directorate}
                  onChange={(e) => handleChange("health_directorate", e)}
                  isClearable
                />
                {isValidationError && (
                  <ValidationHandlerMain isValid={!!data.health_directorate} />
                )}
              </Col>
            </Row>
            <div className="d-flex flex-column align-items-end">
              <Button color="primary" submit>
                {isEdit ? "Update" : "Submit"}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
}

export default PublicEventsAdd;
