// ** React Imports
import { Fragment, useMemo } from "react";
import { useLocation } from "react-router-dom";
// ** Custom Components
import NavbarUser from "./NavbarUser";
import { FormattedMessage } from "react-intl";
import { handleMenuCollapsed } from "../../../../redux/layout";
import { useDispatch } from "react-redux";
import { BackSvg, menuCollapsor } from "../../../../assets/wasfaty/SVG";

import startCase from "lodash/startCase";
//import NavbarBookmarks from './NavbarBookmarks'

import "./style.scss";
import RoutingService from "../../../../views/wasfaty/services/RoutingService";
import { IntlService } from "../../../../views/wasfaty/services";
import Breadcrumbs from "./Breadcrumbs";

const ThemeNavbar = (props) => {
  // ** Props
  const {
    skin,
    setSkin,
    setMenuVisibility,
    menuVisibility,
    menuData,
    menuCollapsed,
    windowWidth,
  } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  // Admin: Path name
  const currentPath = useMemo(() => {
    return RoutingService.getNameByRoute();
  }, [location.pathname]);

  const CollapsToggler = () => {
    return (
      <div
        className="collapsToggler"
        style={{ display: windowWidth <= 1200 ? "block" : "none" }}
      >
        <figure
          className={` ${menuCollapsed ? "rotate" : null}`}
          onClick={() =>
            windowWidth <= 1200
              ? setMenuVisibility(true)
              : dispatch(handleMenuCollapsed(!menuCollapsed))
          }
        >
          {menuCollapsor}
        </figure>
      </div>
    );
  };
  console.log("window width", windowWidth);
  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <CollapsToggler />
        <ul className="mb-0" style={{ paddingLeft: "0px" }}>
          <p className="mb-0 Nav-title d-flex align-items-center">
            {windowWidth > 1200 && <Breadcrumbs />}
            {/* <div
              title="Back"
              onClick={() => window.history.back()}
              className="cursor-pointer me-1"
              style={{
                transform:
                  IntlService.intl?.locale == "sa" ? "rotate(180deg)" : "",
              }}
            >
              <figure className="m-0 back-nav">{BackSvg}</figure>
            </div>
            {currentPath?.titleAr && IntlService.intl?.locale == "sa" ? (
              currentPath?.titleAr
            ) : (
              <FormattedMessage
                id={
                  currentPath.moduleName ||
                  startCase(currentPath?.title) ||
                  "N/A"
                }
                defaultMessage={
                  currentPath.moduleName ||
                  startCase(currentPath?.title) ||
                  "N/A"
                }
              />
            )} */}
          </p>
        </ul>
      </div>

      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
