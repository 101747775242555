import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";

import Download from "../../../assets/images/Download.png";
import Printer from "../../../assets/images/Printer.png";
import User from "../../../assets/images/userpic.jpg";
import DiseaseService from "../../wasfaty/services/DiseaseService";
function DiseaseDetails() {
  const [item, setItem] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    DiseaseService.show(id).then((res) => {
      setItem(res.data.data);
      setLoading(true);
      console.log(res, "DiseaseDetails");
    });
  }, [id]);
  // console.log(item.name,"Hello")
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4 shadow-none">
            <nav
              className="navbar navbar-expand-md "
              style={{ backgroundColor: "#04244A", color: "white" }}
            >
              <div className="container-fluid">
                <Link
                  className="navbar-brand"
                  to="#"
                  style={{ color: "white", fontWeight: "500" }}
                >
                  Disease Details
                </Link>

                <div>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="#"
                      >
                        <img src={Download} className="img-fluid w-75" alt="" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="#">
                        <img src={Printer} className="img-fluid w-75" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
            <CardBody>
              <div>
                {isLoading ? (
                  <Row>
                    <Col md="3" sm="12" className="mb-1">
                      <img src={User} className="img-fluid" alt="" />
                    </Col>
                    <Col md="9" sm="12" className="mb-1">
                      <div className="d-flex align-items-center mb-1  ">
                        <div className="mx-1 w-25">
                          <h2 className="heading_bold">Name :</h2>
                        </div>
                        <div>
                          <h3>
                            <span>{item.name}</span>
                          </h3>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DiseaseDetails;
