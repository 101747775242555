import React, { useState } from "react";
import startCase from "lodash/startCase";
import { precentageIcon } from "../../../assets/wasfaty/SVG";
import { toFixed } from "rc-input-number/lib/utils/MiniDecimal";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Table } from "reactstrap";
import { SC } from "../wasfatyComp/Api/serverCall";

const DataTable = ({ data }) => {
  return (
    <div className="">
      <h3>BottleNeck Information</h3>
      <div className="w-100 overflow-auto" style={{ maxHeight: 700 }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Event ID</th>
              <th>Emergence Date</th>
              {/* <th>Directorate</th> */}
              <th>Address</th>
              <th>Location Link</th>
              {/* <th>Disease ID</th> */}
              <th>Narrative</th>
              <th>Total Suspect Cases</th>
              <th>Total Confirmed Cases</th>
            </tr>
          </thead>
          <tbody>
            {data.map((itm, index) => (
              <tr key={index}>
                <td>{itm.event_ID}</td>
                <td>
                  {new Date(
                    parseInt(itm.emergence.date_of_emergence.$date.$numberLong)
                  ).toLocaleDateString()}
                </td>
                {/* <td>{itm.emergence.health_directorate}</td> */}
                <td>{itm.emergence.address}</td>
                <td>
                  <a
                    href={itm.emergence.location_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Location
                  </a>
                </td>
                {/* <td>{itm.emergence.disease_id}</td> */}
                <td>{itm.emergence.narrative}</td>
                <td>{itm.lineList.total_suspect_cases}</td>
                <td>{itm.lineList.total_confirmed_cases}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const CustomBarChart = ({ title, data, region_id = undefined }) => {
  const [modal, setModal] = useState(false);
  const [bottleneckData, setBottleneckData] = useState([]);

  const toggle = (bottleneck = "") => {
    if (!modal) {
      SC.getCall({
        url: "dashboard/bottleneck-data",
        params: { bottleneck, type: title, region_id },
      }).then((res) => {
        setBottleneckData(res.data.data);
        setModal(true);
      });
    } else {
      setModal(false);
      setBottleneckData([]);
    }
  };

  return (
    <div className="chart-container">
      <Modal isOpen={modal} toggle={toggle} centered size="xl" unmountOnClose>
        <ModalBody className="p-2">
          <DataTable data={bottleneckData} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <div className="head">
        <p className="title">{startCase(title)}</p>

        <div className="chart-legend">
          <div className="legend-item">
            <span className={`gradient-legend target-bar-${title}`}></span>
            No. of met targets
          </div>
          <div className="legend-item">
            <span className="pattern-legend"></span>
            Percentage
          </div>
        </div>
      </div>

      <div
        className="bar-chart mt-5"
        style={{ justifyContent: data?.data?.length == 1 ? "center" : null }}
      >
        {data?.data.map((item, index) => {
          if (index > 2) return null;
          return (
            <div key={index}>
              <div className="d-flex flex-column align-items-center">
                <div className="bar-group " key={index}>
                  <div
                    className={`bar target-bar-${title}`}
                    style={{
                      height:
                        item.met_cases * 1.5 <= 150
                          ? item.met_cases * 1.5
                          : 150,
                    }}
                  >
                    <span className="bar-value">{item.met_cases}</span>
                  </div>
                  <div
                    className="bar percentage-bar"
                    style={{ height: item.metCasesPercentage * 1.5 }}
                  >
                    <span className="bar-value">
                      {item.metCasesPercentage.toFixed(1)}%
                    </span>
                  </div>
                </div>
                <div className="bar-label text-center">{item.region_name}</div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="d-flex justify-content-center gap-5 align-items-center mt-3 mx-1 info-section-container">
        <div className="info-section">
          <div className=" ">
            <p className="title">Range</p>
          </div>{" "}
          <p className="days-info">
            {data?.range} <span className="days">days</span>
          </p>
        </div>
        <div className="horizontal-devider" />
        <div className="info-section">
          <div className="">
            <p className="title">Median</p>
          </div>{" "}
          <p className="days-info">
            {data?.median} <span className="days">days</span>
          </p>
        </div>
      </div>

      <div className="bottle-neck-section mt-1 mx-1">
        <p className="title">Top 3 bottlenecks </p>

        <div className="d-flex flex-column mt-2">
          {data?.bottleneck.map((item, index) => (
            <p
              key={index}
              onClick={() => toggle(item)}
              className="badge-custom cursor-pointer"
            >
              {startCase(item)}{" "}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomBarChart;
