import { lazy } from "react";
import DepartmentList from "../../views/pages/List/DepartmentList";
import DiseaseList from "../../views/pages/List/DiseaseList";
import NotificationList from "../../views/pages/List/NotificationList";
import PublicEventsList from "../../views/pages/List/PublicEvents";

//const Pagination = lazy(() => import("../../views/pages/List/Pagination"));
const UserList = lazy(() => import("../../views/pages/List/UserList"));
const RoleList = lazy(() => import("../../views/pages/List/RoleList"));
const RegionList = lazy(() => import("../../views/pages/List/RegionList"));
const ModuleList = lazy(() => import("../../views/pages/List/ModuleList"));
const SiteList = lazy(() => import("../../views/pages/List/SiteList"));
const EventTypeList = lazy(() =>
  import("../../views/pages/List/EventTypeList")
);
const ObligationGraph = lazy(() => import("../../views/pages/Graph/pharmacy"));
// %templateImport%
const OutbreakInformation = lazy(() =>
  import("../../views/pages/List/OutbreakInformation")
);

const ListRoutes = [
  {
    element: <UserList />,
    path: "/Users",
    route: "Users",
    slug: "Users",
    title: "Users",
  },

  {
    element: <RoleList />,
    path: "/Roles",
    route: "Roles",
    slug: "Roles",
    title: "Roles",
  },
  {
    element: <DepartmentList />,
    path: "/Departments",
    route: "Departments",
    slug: "Departments",
    title: "Departments",
  },
  {
    element: <RegionList />,
    path: "/Regions",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
  },
  {
    element: <DiseaseList />,
    path: "/EventType",
    route: "EventType",
    slug: "EventType",
    title: "EventType",
  },
  {
    element: <ModuleList />,
    path: "/Modules",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
  },
  {
    element: <SiteList />,
    path: "/Sites",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
  },

  {
    element: <EventTypeList />,
    path: "/Events",
    route: "EventType",
    slug: "EventType",
    title: "Events",
  },

  // {
  //   element: <VideoSessions />,
  //   path: "/Virtual-Sessions",
  //   route: "Video Sessions",
  //   slug: "Virtual-Sessions",
  //   title: "Virtual Sessions",
  //   onlyFor: ["admin", "regional representatives", "agent"],
  // },

  {
    element: <OutbreakInformation />,
    path: "/Outbreak-717",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
  },
  {
    element: <ObligationGraph />,
    path: "/Outbreak-717/Dashboard",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
  },

  {
    element: <NotificationList />,
    path: "/Notifications",
    route: "Notifications",
    slug: "Notifications",
    title: "Notifications",
  },
  {
    element: <PublicEventsList />,
    path: "/Public-Events",
    route: "Public-Events",
    slug: "Public-Events",
    title: "Public-Events",
  },

  // %template%
];
export default ListRoutes;
