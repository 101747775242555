import Language from "./Language";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import get from "lodash/get";

import { SC } from "../../../../views/wasfaty/Api/serverCall";
import UserDropdown from "../../../components/Profile/ProfileDropDown";
import { userTopIcon } from "../../../../assets/wasfaty/SVG";
import AuthUser from "../../../../views/wasfaty/services/AuthService";
import { selectThemeColors } from "../../../../utility/Utils";
import { useEffect, useState } from "react";
import { RegionService } from "../../../../views/wasfaty/services";
import orderBy from "lodash/orderBy";

const NavbarUser = () => {
  const navigate = useNavigate();
  const [userRegions, setUserRegions] = useState([]);
  const [selectedRegion, setSelectedRegions] = useState("");

  const logOut = (e) => {
    e.preventDefault();
    navigate("/login");

    SC.getCall({ url: "logout" }).then((response) => {});
    localStorage.clear();
  };
  useEffect(() => {
    RegionService.getRegionsByUser().then((res) => {
      const data = get(res, "data.data", []);
      if (data.length) {
        setUserRegions(
          orderBy(
            data.map((item) => ({
              ...item,
              value: item._id,
              label: item?.region?.name
                ? item.region.name + " - " + item.name
                : item.name,
            })),
            (region) => {
              return region?.name?.length;
            }
          )
        );
        const activeRegion = data.find((item) => item.is_active);
        if (activeRegion)
          setSelectedRegions({
            value: activeRegion._id,
            label: activeRegion?.region?.name
              ? activeRegion.region.name + " - " + activeRegion.name
              : activeRegion.name,
          });
      } else {
        setSelectedRegions("");
      }
    });
  }, []);

  const handleSubmit = (selectedValue) => {
    if (selectedValue) {
      RegionService.setRegionByUser({ role_id: selectedValue.value });
    }
  };
  return (
    <>
      <ul
        className="nav  d-flex align-items-center ms-auto flex-nowrap"
        style={{ marginTop: -5 }}
      >
        <li className="role-select-dropdown">
          <Select
            name="Roles"
            value={selectedRegion}
            onChange={(obj) => {
              setSelectedRegions(obj);
              handleSubmit(obj);
            }}
            theme={selectThemeColors}
            className="react-select"
            classNamePrefix="select"
            options={userRegions}
            isClearable={false}
          />
        </li>
        <li className="user-info">
          {/* <Language /> */}
          <figure>{userTopIcon}</figure>
          <div>
            <p className="hed">{AuthUser.user?.name}</p>

            <p className="role">
              {AuthUser?.user?.type || AuthUser?.user?.roles?.[0]?.name}
            </p>
          </div>
        </li>
        <li className="mx-1 ">
          <Language />
        </li>
        <li>
          <UserDropdown logOut={logOut} />
        </li>
      </ul>
    </>
  );
};
export default NavbarUser;
