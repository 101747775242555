import {
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import PoweredByLogo from "../../assets/images/pages/AscendPower.png";
import loginLogo from "../../assets/images/pages/FeatureImage.svg";
import "./style.scss";
import AlertDismissable from "./assets/Alert";
import eficaXLogo from "../../assets/images/moh-logo.png";
import EfficaX from "../../assets/images/pages/loginlogofooter.svg";
import { SC } from "../wasfaty/Api/serverCall";
import OTPInput from "react-otp-input";

import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import { selectThemeColors } from "../../utility/Utils";
import { RegionService } from "../wasfaty/services";
import moment from "moment";
const Login = () => {
  const [validation, setValidation] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [Message, setMessage] = useState("");
  const [counter, setCounter] = useState(-100);
  const [canResend, setCanResend] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const counterRef = useRef();
  const resendOtpRef = useRef();

  counterRef.current = counter;
  const [Data, setData] = useState({
    email: "",
    password: "",
    role: "",
    otp: "",
    otpState: false,
    roleState: false,
    // captcha: false,
  });
  const navigate = useNavigate();
  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
  };

  // useEffect(() => {
  //   setInterval(() => {
  //     if (counterRef.current > 0) {
  //       setCounter((prev) => prev - 1);
  //     }
  //   }, 1000);
  // }, []);
  useEffect(() => {
    RegionService.getRegionsByUser().then((res) => {
      const data = get(res, "data.data", []);
      if (data.length) {
        setRoleOptions(
          data.map((item) => ({
            ...item,
            value: item._id,
            label: item.name,
          }))
        );
        const activeRegion = data.find((item) => item.is_active);
        if (activeRegion)
          handlechange("role", {
            value: activeRegion._id,
            label: activeRegion?.region?.name
              ? activeRegion.region.name + " - " + activeRegion.name
              : activeRegion.name,
          });
      } else {
        handlechange("role", "");
      }
    });
  }, [Data.roleState]);

  useEffect(() => {
    if (counter > 0) {
      resendOtpRef.current = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      clearInterval(resendOtpRef.current);
      setCanResend(true); // Enable the Resend OTP button after 3 seconds
    }

    return () => clearInterval(resendOtpRef.current);
  }, [counter]);

  const sendOtp = () => {
    setCounter(300);
    setCanResend(false);
    SC.postCallWithoutAuth({ url: "sendOtp", data: Data }).then((response) => {
      if (response.status === 200 && response.data) {
        toast.success(response.data.data);
        setData({ ...Data, otpState: true });
        setLoading(false);
        setVisible(false);
      } else {
        setCounter(0);
        toast.error(response?.response?.data?.error);

        setLoading(false);
      }
    });
  };
  const handleResendOtp = () => {
    sendOtp();
  };
  const handleSubmit = async (data) => {
    data.preventDefault();

    if (Data.email === "" || Data.password === "") {
      // if (!Data.captcha) {
      //   toast.error("Please fill the captcha!");
      // }

      setValidation(true);
    } else {
      setLoading(true);
      setValidation(false);

      if (Data.otpState) {
        SC.postCallWithoutAuth({ url: "loginWithOtp", data: Data }).then(
          (response) => {
            if (response.status === 200 && response.data) {
              localStorage.setItem(
                "accessToken",
                JSON.stringify(response?.data?.data?.accessToken)
              );

              localStorage.setItem(
                "refreshToken",
                JSON.stringify(response?.data?.data?.refreshToken)
              );

              SC.getCall({ url: "token" }).then((res) => {
                localStorage.setItem("userData", JSON.stringify(res.data.data));
                setLoading(false);
                // window.location.reload(false);
                console.log("system user data", res.data.data);
                if (res.data.data?.role_region?.length <= 0) {
                  window.location.reload(false);
                } else setData({ ...Data, otpState: false, roleState: true });
              });
              setVisible(false);
            } else {
              toast.error(response?.response?.data?.error);
              // setMessage(response?.response?.data?.error);
              setLoading(false);
            }
          }
        );
      } else if (Data.roleState) {
        if (Data.role === "") {
          setValidation(true);
          setLoading(false);
        } else {
          if (Data.role) {
            RegionService.setRegionByUser({ role_id: Data.role.value });
          }
        }
      } else {
        sendOtp();
      }
    }
  };
  // return null;

  return (
    <Container fluid className="authLayout">
      <div className="headercontent">
        <div className="header_child">
          <img src={eficaXLogo} alt="logo" style={{ width: "150px" }} />
          <p className="info_title">
              Epilog: Public Health Events Surveillance System in Saudi Arabia
          </p>
        </div>
        {/* <div>
          <Language />
        </div> */}
      </div>
      <Row className="authLayout__content w-100">
        <Col lg="5" className="authLayout__imageContainer">
          <img src={loginLogo} />
        </Col>
        <Col md="10" lg="5" className="authLayout__formContainer">
          <div className="authLayout__formContainer__box">
            <div className="authLayout__formContainer__box__children">
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="login-header">
                    <p className="heading">
                      {Data.roleState
                        ? "Role Selection"
                        : Data.otpState
                        ? "OTP Verification"
                        : "Sign In"}
                    </p>
                    <p className="info ">
                      {Data.otpState
                        ? "We Will send you a one time password on your registred Email"
                        : Data.roleState
                        ? ""
                        : "Sign in to Your Account"}
                    </p>
                  </div>
                  <div className="">
                    <AlertDismissable
                      visible={visible}
                      setVisible={setVisible}
                      msg={Message?.toString?.()}
                      invalid={"Email or Password invalid"}
                    />
                  </div>

                  {Data.roleState ? (
                    <div className="mb-1 position-relative">
                      <FormGroup>
                        <Label for="role" style={{ color: "white" }}>
                          Roles *
                        </Label>
                        <Select
                          name="Roles"
                          id="role"
                          value={Data.role}
                          onChange={(obj) => {
                            handlechange("role", obj);
                          }}
                          theme={selectThemeColors}
                          classNamePrefix="select"
                          options={roleOptions.map((item) => ({
                            label: item?.region?.name
                              ? item.region.name + " - " + item.name
                              : item.name,
                            value: item.value,
                          }))}
                          isClearable={false}
                        />
                        {Data.roleState && !Data.role && validation && (
                          <p className="error-message text-danger">
                            Please Select Role*
                          </p>
                        )}
                      </FormGroup>
                    </div>
                  ) : !Data.otpState ? (
                    <div>
                      <div className="mb-1">
                        <Input
                          className="loginFields"
                          type="email"
                          placeholder="Email"
                          onChange={(e) =>
                            handlechange("email", e.target.value.toLowerCase())
                          }
                          invalid={
                            Data.email === "" && validation ? true : false
                          }
                        />
                        <FormFeedback
                          invalid={
                            Data.email === "" && validation ? "true" : "false"
                          }
                        >
                          Please enter email address
                        </FormFeedback>
                      </div>

                      <div className="mb-1">
                        <Input
                          className="loginFields"
                          autoComplete="new-password"
                          type="password"
                          placeholder="Password"
                          onChange={(e) =>
                            handlechange("password", e.target.value)
                          }
                          invalid={
                            Data.password === "" && validation ? true : false
                          }
                        />

                        <FormFeedback
                          invalid={
                            Data.password === "" && validation
                              ? "true"
                              : "false"
                          }
                        >
                          Please enter Password
                        </FormFeedback>
                      </div>

                      {/* <div className="d-flex justify-content-center">
                        <ReCAPTCHA
                          sitekey="6LcaG3MmAAAAAHKeyUUldKw96BYmXPjX6uNLEFXV"
                          onChange={(e) => handlechange("captcha", true)}
                          onExpired={(e) => handlechange("captcha", false)}
                        />
                      </div> */}
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center mt-4 mb-4">
                      <OTPInput
                        renderInput={(props) => <input {...props} />}
                        value={Data.otp}
                        onChange={(e) => handlechange("otp", e)}
                        numInputs={6}
                        inputStyle={{
                          width: "15%",
                          height: "80px",
                          fontSize: 19,
                          color: "white",
                          background: "#04082468",
                          fontWeight: 700,
                          marginLeft: "10px",
                          border: "none",
                          borderRadius: "10px",
                          maxWidth: "50px",
                        }}
                      />
                    </div>
                  )}

                  {/* {!Data.otpState ? (
        <div className="d-flex justify-content-center mt-4 mb-4">
          <Button
            style={{ cursor: "pointer" }}
            onClick={handleResendOtp}
            disabled={!canResend}
          >
            Resend OTP
          </Button>
          {counter > 0 && <span style={{ marginLeft: "10px" }}>{counter}s</span>}
        </div>
      ) : null} */}

                  {!Data.otpState ? (
                    <div className="remember-me row">
                      <div className="form-check mb-1 col-md-6 col-sm-12">
                        {/* <Input type="checkbox" id="remember-me" className="" />
                        <Label className="form-check-label" for="remember-me">
                          Remember Me
                        </Label> */}
                      </div>

                      <div className="col-md-6 col-sm-12 p-0 d-sm-flex justify-content-end">
                        {!Data.otpState && !Data.roleState && (
                          <Link to="/forgot-password">
                            <small>Forgot Password?</small>
                          </Link>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end">
                      {counter > 0 ? (
                        <small style={{ fontSize: 14 }}>
                          {moment.utc(counter * 1000).format("mm:ss")}
                        </small>
                      ) : (
                        <small onClick={sendOtp} style={{ cursor: "pointer" }}>
                          Resend OTP
                        </small>
                      )}
                    </div>
                  )}

                  <Button
                    type="submit"
                    color="primary button-main"
                    block
                    className="p-1 authButton"
                    disabled={loading}
                  >
                    {!loading ? (
                      Data.otpState || Data.roleState ? (
                        "Submit"
                      ) : (
                        "Sign in"
                      )
                    ) : (
                      <span>
                        <Spinner size="sm" className="mx-1" />
                        {Data.otpState || Data.roleState
                          ? "Submitting..."
                          : "Signing in..."}
                      </span>
                    )}
                  </Button>
                </div>
              </Form>
              <div className="pt-2 d-flex justify-content-center">
                {/* <div className="mr-1 ">
                  <a
                    href="https://ascend.com.sa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={EfficaX}
                      alt="logo"
                      className="authLayout__footer--logo1 "
                    />
                  </a>
                </div> */}
                <div>
                  <a
                    href="https://ascend.com.sa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={PoweredByLogo}
                      alt="logo"
                      className="authLayout__footer--logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="authLayout__footer">
        {" "}
        {/* <a
          href="https://ascend.com.sa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PoweredByLogo}
            alt="logo"
            className="authLayout__footer--logo"
          />
        </a> */}
      </Row>
    </Container>
  );
};

export default Login;
