import React, { useRef, useState } from "react";
import { DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import { useRTL } from "@hooks/useRTL";
import { FormattedMessage } from "react-intl";

function DropdownB({
  row,
  showData,
  DeleteData,
  onEdit = () => {},
  isDelete = true,
  isExport = false,
  isDetails = true,
  isEdit = true,
  direction = "",
  onClose = () => {},
  exportItem = () => {},
  style = {},
  isSetPermission = false,
  setPermission,
  history,
  pdf,
}) {
  const parentRef = useRef();
  const [update, setUpdate] = useState(false);
  const [isRtl] = useRTL();

  let p = parentRef?.current?.getBoundingClientRect?.();

  return (
    <div
      ref={(ref) => {
        parentRef.current = ref;
        setUpdate((r) => !r);
      }}
    >
      <Dropdown
        isOpen={true}
        toggle={onClose}
        style={{
          position: "absolute",
          transform: `translateX(${
            row.clientX +
            (isRtl ? 30 : -180) -
            (isRtl ? p?.right : p?.left || 0)
          }px) translateY(${row.clientY - 50 - (p?.top || 0)}px)`,
          zIndex: 100,
        }}
      >
        <DropdownMenu>
          {isDetails && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                showData(row._id);
              }}
            >
              <FormattedMessage id="Details" defaultMessage="Details" />
            </DropdownItem>
          )}

          {isEdit && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                onEdit(row._id);
              }}
            >
              <FormattedMessage id="Edit" defaultMessage="Edit" />
            </DropdownItem>
          )}

          {isDelete && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                DeleteData(row._id);
              }}
            >
              <FormattedMessage id="Delete" defaultMessage="Delete" />
            </DropdownItem>
          )}

          {isExport && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                exportItem(row);
              }}
            >
              <FormattedMessage id="Export" defaultMessage="Export" />
            </DropdownItem>
          )}
          {isSetPermission && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                setPermission(row._id);
              }}
            >
              <FormattedMessage
                id="Manage Permissions"
                defaultMessage="Manage Permissions"
              />
            </DropdownItem>
          )}
          {history && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                history(row);
              }}
            >
              <FormattedMessage id="History" defaultMessage="History" />
            </DropdownItem>
          )}
          {pdf && (
            <DropdownItem
              href="/"
              tag="a"
              onClick={(e) => {
                e.preventDefault();
                pdf({ data: row });
              }}
            >
              <FormattedMessage id="PDF" defaultMessage="PDF" />
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
export default DropdownB;
