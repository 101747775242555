import { MoreVertical } from "react-feather";
const Mock = [
  {
    id: 1,
    email: "example@gmail.com",
    full_name: "Afzal",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 2,
    email: "example@gmail.com",
    full_name: "Uzair",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 3,
    email: "example@gmail.com",
    full_name: "Abuzar",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 4,
    email: "example@gmail.com",
    full_name: "Ahmad",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 5,
    email: "example@gmail.com",
    full_name: "Haider",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 6,
    email: "example@gmail.com",
    full_name: "Ahmar",
    post: "Non Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 7,
    email: "example@gmail.com",
    full_name: "Ali",
    post: "Non Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 8,
    email: "example@gmail.com",
    full_name: "Abubakar",
    post: "Non Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 9,
    email: "example@gmail.com",
    full_name: "Afzal",
    post: "Non Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 10,
    email: "example@gmail.com",
    full_name: "Abuzar",
    post: "Non Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
  {
    id: 11,
    email: "example@gmail.com",
    full_name: "Uzair",
    post: "Moh",
    region: "empty",
    salary: <MoreVertical />,
  },
];
export default Mock;
