import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Input } from "reactstrap";

import debounce from "lodash/debounce";
import { useIntl } from "react-intl";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";
import { Spinner } from "reactstrap";
import { useDimensions } from "../../../hooks/useDimensions";

const SearchComponent = forwardRef(
  (
    { onSearchEnd = () => {}, onSearchClick = () => {}, isVisiable = false },
    ref
  ) => {
    const intl = useIntl();

    const [search, setSearch] = useState("");
    const searchRef = useRef();
    searchRef.current = search;
    const [searchLoading, setSearchLoading] = useState(false);
    const { isMobile } = useDimensions();

    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const searchClicked = useRef();
    searchClicked.current = isSearchClicked;

    useImperativeHandle(ref, () => ({
      searchText: searchRef.current,
      setSearchLoading: (check) => {
        setSearchLoading(check);
      },
      isSearchClicked: searchClicked.current,
    }));

    const onSearch = useRef(
      debounce(() => {
        onSearchEnd({ isFromSearch: true, search: searchRef.current });
      }, 1000)
    ).current;

    return (
      <div
        style={{
          flexDirection: "row",
          // marginTop: isMobile ? 10 : 0,
          display: isVisiable ? "flex" : "none",
        }}
      >
        <div>
          {searchLoading && isSearchClicked && (
            <Spinner
              color="primary"
              style={{
                height: 18,
                width: 18,
                position: "absolute",
                zIndex: 100,
                marginLeft: 220,
                marginTop: 11,
                background: "white",
              }}
            />
          )}
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
            type="text"
            className="round"
            style={{
              width: 250,
              display: isSearchClicked ? "block" : "none",
            }}
            value={search}
            placeholder={intl.formatMessage({
              id: "Search here",
              defaultMessage: "Search here",
            })}
            onChange={(e) => {
              setSearch(e.target.value);
              searchRef.current = e.target.value;
              onSearch(e.target.value);
            }}
          />
        </div>
        <SearchIcon
          // fill={"red"}
          style={{
            marginRight: 10,
            marginLeft: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            setIsSearchClicked((s) => {
              onSearchClick(!s);

              return !s;
            });
          }}
        />
      </div>
    );
  }
);

export default SearchComponent;
