import axios from "axios";

class Service {
  constructor() {}

  checkInternet() {
    return new Promise((resolve) => {
      axios({
        url: "https://storage.ascend.com.sa",
        method: "GET",
        timeout: 5000,
      })
        .then((res) => {
          if (res.status !== 200 || res.message?.includes?.("timeout")) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((e) => {
          resolve(false);
        });
    });
  }
}

const UtilityService = new Service();
export default UtilityService;
