import { SC } from "./Api/serverCall";

export class Resources {
  async create(data) {
    console.log("this.routes", this.routes);
    return SC.postCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.create || "-create"),
      data,
    });
  }

  async find(params = {}) {
    return SC.getCall({
      url:
        this.route +
        (this.disableDefault
          ? ""
          : typeof this.routes?.find === "string"
          ? this.routes?.find
          : "-index"),
      params,
    });
  }

  async show(id) {
    return SC.getCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.show || `-show`) +
        `/${id}`,
    });
  }

  async update(id, data) {
    return SC.putCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.update || `-update`) +
        `/${id}`,
      data,
    });
  }
  async updateBody(id, data) {
    let body = { ...data, id };
    return SC.postCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.update || `-update`),
      data: body,
    });
  }

  async delete(id) {
    return SC.deleteCall({
      url:
        this.route +
        (this.disableDefault ? "" : this.routes?.delete || `-delete`) +
        `/${id}`,
    });
  }

  async activateDeactivate(body) {
    return SC.postCall({
      url:
        this.route +
        (this.disableDefault? "" : this.routes?.activation || `-activation`),
        data: body,
    });
  }
}

export const Singleton = (SingletonClass) => {
  if (!SingletonClass.instance) {
    SingletonClass.instance = new SingletonClass();
  }
  return SingletonClass.instance;
};
