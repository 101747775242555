import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable, makeObservable, observable, action } from "mobx";
import startCase from "lodash/startCase";
import { emailValidator, phoneValidator } from "../Helper/utils";
import { AuthService } from "../services";

export class DepartmentModal {
  isValidation = false;
  isSitesLoading = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  _id = "";
  updated_at = "";
  name = "";
  email = "";
  phone = "";
  category = "";
  // module = [];
  type = "regional representatives";

  constructor(user) {
    if (user) {
      this.setPayload(user);
    }
    makeAutoObservable(this);
  }

  getServerPayload() {
    return {
      name: this.name,
      // email: this.email,

      // phone: "+966 " + this.phone?.replaceAll?.("+966", "").trim(),
      department717: true,
      // module_ids: this.module.map((item) => item.value),
    };
  }

  getReview() {
    let data = this.getServerPayload();
    return data;
  }
  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }
  setPayload(payload) {
    if (payload) {
      let {
        user,
        _id,
        // modules,

        nupco_sites,
        phone,
        type,
      } = payload;
      const except = ["region_id", , "sites"];

      Object.entries(payload).forEach(([name, value]) => {
        if (!except.includes(name)) {
          this.setAttribute(name, this.isDropDown(name, value));
        }
      });

      this.type = { label: AuthService.getRoleLabel(type), value: type };
      this.phone = phone?.replace("+966", "").trim();
    }
  }

  isDropDown(name, value) {
    let dropDownKeys = ["category", "type"];
    if (dropDownKeys.includes(name)) {
      value = { value, label: startCase(value) };
    }

    return value;
  }

  isValid(isAdmin) {
    let check = !!this.name;
    // emailValidator(this.email) &&
    // (this.password || this.isEdit) &&
    // phoneValidator(this.phone);

    return check;
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }
  setIsReview(check) {
    this.isReview = check;
  }

  types() {
    return [
      "agent",
      "admin",
      "regional representatives",
      "obligation department",
      "ascend team",
      "nupco_admin",
      "facility_manager",
    ];
  }
  get accessableRoles() {
    return AuthService.roles.filter(
      (role) => role.id >= AuthService.currentRoleLevel
    );
  }
}
