import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable, makeObservable, observable, action } from "mobx";
import startCase from "lodash/startCase";
import { emailValidator, phoneValidator } from "../Helper/utils";
import { AuthService } from "../services";

export class UserModal {
  isValidation = false;
  isSitesLoading = false;
  isSubmit = false;
  isReview = false;
  isEdit = false;
  _id = "";
  updated_at = "";
  name = "";
  email = "";
  phone = "";
  password = "";
  region_ids = [];
  category = "";
  module = [];
  role = ""; // Replaced 'type' with 'role'
  site_id = [];
  cluster_id = [];
  additionalRoles = [];
  type = "";
  isSameRoles = false;
  constructor(user) {
    if (user) {
      this.setPayload(user);
    }
    makeAutoObservable(this);
  }

  getServerPayload() {
    return {
      name: this.name,
      email: this.email,
      password: this.password,
      phone: "+966 " + this.phone?.replaceAll?.("+966", "").trim(),
      // role: this.role.value, // Replaced 'type' with 'role'
      // selectedRole: this.role, // Replaced 'type' with 'role'
      region_ids: this.region_ids.map((r) => r._id),
      region_role_ids: this.additionalRoles,
      site_ids: this.site_id.map((s) => s._id),
      nupco_site_cluster_ids: this.site_id.map((s) => s._id),
      cluster_ids: this.cluster_id.map((c) => c._id),
      category: this.category.value,
      module_ids: this.module.map((item) => item.value),
    };
  }
  handleAddRole() {
    this.additionalRoles.push({
      id: uuidv4(),
      role: "",
      region: "",
    });
  }

  handleRemoveRole(index) {
    this.additionalRoles.splice(index, 1);
    this.hasMultipleOccurrences();
  }
  getAdditionalRoles() {
    this.hasMultipleOccurrences();
    return this.additionalRoles;
  }
  handleChange(index, field, value) {
    this.additionalRoles[index] = value;
    this.hasMultipleOccurrences();
  }
  getReview() {
    let data = this.getServerPayload();
    return {
      ...data,

      region: this.region_ids.map((s, i) => (i !== 0 ? ", " + s.name : s.name)),
      site: this.site_id.map((s, i) => (i !== 0 ? ", " + s.name : s.name)),
      module: this.module.map((item, i) =>
        i !== 0 ? ", " + item.label : item.label
      ),
      cluster: this.cluster_id.map((item, i) =>
        i !== 0 ? ", " + item.label : item.label
      ),
    };
  }

  setAttribute(name, value) {
    if (name) {
      this[name] = value;
    }
  }
  setPayload(payload) {
    if (payload) {
      this.isValidation = true;
      let {
        region,
        sites,
        user,
        _id,
        modules,
        clusters,
        nupco_sites,
        phone,
        role,
        roles, // Replaced 'type' with 'role'
      } = payload;
      const except = ["region_ids", "modules", "sites", "role"]; // Replaced 'type' with 'role'

      Object.entries(payload).forEach(([name, value]) => {
        if (!except.includes(name)) {
          this.setAttribute(name, this.isDropDown(name, value));
        }
      });
      this.role = { label: roles[0]?.name, value: roles[0]?._id }; // Replaced 'type' with 'role'
      this.phone = phone?.replace("+966", "").trim();

      if (role !== "admin") {
        // Check the role before setting region_ids
        if (region?.length) {
          this.region_ids = region.map((m) => ({
            ...m,
            value: m._id,
            label: m.name,
          }));
        }
        this.region_id = this.region_ids;
      } else {
        this.region_ids = [];
      }

      let allsites = [];
      if (sites?.length) {
        allsites = sites.map((m) => ({
          ...m,
          value: m._id,
          label: m.name,
        }));
      }

      if (nupco_sites?.length) {
        allsites = [
          ...allsites,
          ...nupco_sites.map((m) => ({
            ...m,
            value: m._id,
            label: m.phc_name_eng,
            name: m.phc_name_eng,
          })),
        ];
      }

      this.site_id = allsites;
      if (!this.site_id.length) {
        this.site = [{ value: "", label: "N/A" }];
      }

      if (modules?.length) {
        this.module = modules.map((m) => ({
          ...m,
          value: m._id,
          label: m.name,
        }));
      } else {
        this.module = [{ value: "", label: "N/A" }];
      }

      if (clusters?.length) {
        this.cluster_id = clusters.map((m) => ({
          ...m,
          value: m._id,
          label: m.name,
        }));
      } else {
        this.cluster_id = [{ value: "", label: "N/A" }];
      }

      this.additionalRoles =
        payload?.role_region?.length > 0
          ? payload.role_region.map((item) => {
              return {
                id: uuidv4(),
                // role: {
                //   ...item.role,
                value: item._id,
                label: item.name,
                // },

                // region: {
                //   ...item.region,
                //   value: item.region._id,
                //   label: item.region.name,
                // },
              };
            })
          : payload.roles.map((item) => ({
              id: uuidv4(),
              role: { ...item, value: item._id, label: item.name },
              region: null,
            }));

      this.hasMultipleOccurrences();
    }
  }

  isDropDown(name, value) {
    let dropDownKeys = ["category", "role"]; // Replaced 'type' with 'role'
    if (dropDownKeys.includes(name)) {
      value = { value, label: startCase(value) };
    }

    return value;
  }

  isValid(isAdmin) {
    let check =
      this.name &&
      emailValidator(this.email) &&
      (this.password || this.isEdit) &&
      phoneValidator(this.phone) &&
      this.additionalRoles.every((role) => role); // Replaced 'type' with 'role'

    // if (this.isModuleVisiable) {
    //   check = check && this.module.length;
    // }

    // if (this.isRegionRequired) {
    //   check = check && this.region_ids?.length;
    // }

    // if (this.isSiteRequired) {
    //   check = check && this.site_id.length;
    // }

    return check;
  }
  hasMultipleOccurrences() {
    const frequencyMap = {};

    this.additionalRoles.forEach((item) => {
      frequencyMap[item.value] = (frequencyMap[item.value] || 0) + 1;
    });
    console.log(
      "Object.values(frequencyMap).some((count) => count > 1);",
      Object.values(frequencyMap).some((count) => count > 1)
    );
  }

  setValidation(check) {
    this.isValidation = check;
  }

  setIsSubmit(check) {
    this.isSubmit = check;
  }
  setIsReview(check) {
    this.isReview = check;
  }

  types() {
    return [
      "agent",
      "admin",
      "regional representatives",
      "obligation department",
      "ascend team",
      "nupco_admin",
      "facility_manager",
    ];
  }

  get isModuleVisiable() {
    switch (
      this.role?.value // Replaced 'type' with 'role'
    ) {
      case "admin":
        return false;

      default:
        return true;
    }
  }

  get isRegionRequired() {
    switch (
      this.role?.label // Replaced 'type' with 'role'
    ) {
      case "Admin":
        return false;

      default:
        return true;
    }
  }

  get isClusterRequired() {
    switch (
      this.role?.value // Replaced 'type' with 'role'
    ) {
      case "admin":
      case "regional representatives":
      case "nupco_admin":
        return false;

      default:
        return true;
    }
  }

  get isSiteRequired() {
    switch (
      this.role?.value // Replaced 'type' with 'role'
    ) {
      case "admin":
      case "regional representatives":
        return false;

      default:
        return true;
    }
  }

  get accessableRoles() {
    return AuthService.roles.filter(
      (role) => role.id >= AuthService.currentRoleLevel
    );
  }

  get isUnique() {
    const frequencyMap = {};

    this.additionalRoles.forEach((item) => {
      frequencyMap[item.value] = (frequencyMap[item.value] || 0) + 1;
    });

    return Object.values(frequencyMap).some((count) => count > 1);
  }
}
