import { useNavigate } from "react-router-dom";
import { Form, Button, Input, Container, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
// import { SC } from "../../utility/Helper/helper";
// import { validatePassword2 } from "../../utility/Helper/ValidatePassword";
// import { reset_password } from "../../utility/Helper/endPoints";
import AlertDismissable from "./assets/Alert";
import { toast } from "react-hot-toast";
import PoweredByLogo from "../../assets/images/moh-logo.png";
import { useParams } from "react-router-dom";
import "./style.scss";
import eficaXLogo from "../../assets/wasfaty/brandlogo.svg";
// import { eficaXLogo } from "../../assets/images/svg/SvgJSX";
// import Language from "../../utility/Helper/Language/Language";
import { validatePassword2 } from "./RenderFeedback";
import { SC } from "../wasfaty/Api/serverCall";

const ResetPasswordV1 = () => {
  const [Data, setData] = useState({ email: "", password: "" });
  const [validation, setValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();
  let { id } = useParams();
  let url = window.location.pathname;
  const params = useParams();
  let token = params.token;

  useEffect(() => {
    localStorage.clear();

    setData({
      ...Data,
      email: params.email,
    });
  }, []);
  const handleChange = (key, value) => {
    setValidation(false);
    setData({ ...Data, [key]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: Data.email,
      password: Data.password,
      token: token,
      password_confirmation: Data.password,
    };

    if (!validatePassword2(data.password)) {
      setValidation(true);
      setErrorMessage(
        "Password should contain lowercase, uppercase, number and a special character! ex: Ms5C!lcB"
      );
    } else {
      setErrorMessage("");
      setValidation(false);
      setLoading(true);
      SC.postCall({ url: "reset-password", data }).then((res) => {
        if (res.status === 200) {
          toast.success("Password Changed Successfully!");
          navigate("/login");
        } else {
          toast.error(res.response?.data?.data?.[0]);
        }
        setLoading(false);
      });
    }
  };
  return (
    <Container fluid className="authLayout">
      <div className="headercontent">
        <div>
          {/* <img src={eficaXLogo} alt="logo" style={{ width: "150px" }} /> */}
        </div>
        {/* <div>
          <Language />
        </div> */}
      </div>
      <Row className="authLayout__content w-100">
        <Col md="10" lg="5" className="authLayout__formContainer">
          <div className="authLayout__formContainer__box">
            <div className="authLayout__formContainer__box__children">
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="login-header">
                    <p className="heading-otherscreeens">Reset Password</p>
                    <p className="info">
                      Set the new password for your account "{Data.email}"
                    </p>
                  </div>
                  <AlertDismissable
                    visible={validation}
                    setVisible={setValidation}
                    msg={"Something went to wrong!"}
                    invalid={errorMessage}
                  />
                  <div>
                    <div className="mb-1">
                      <Input
                        autoComplete="new-password"
                        type="password"
                        className="loginFields"
                        placeholder="Password"
                        value={Data.password}
                        autoFocus
                        onChange={(e) =>
                          handleChange(
                            "password",
                            e.target.value.replace(/ /g, "")
                          )
                        }
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    color="primary button-main"
                    block
                    className="p-1 authButton"
                  >
                    {!loading ? (
                      "Reset Password"
                    ) : (
                      <>
                        <span>Submitting...</span>
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="authLayout__footer">
        {" "}
        <a
          href="https://ascend.com.sa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PoweredByLogo}
            alt="logo"
            className="authLayout__footer--logo"
          />
        </a>
      </Row>
    </Container>
  );
};

export default ResetPasswordV1;
