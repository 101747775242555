import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Spinner, Container, Row, Col } from "reactstrap";

import PoweredByLogo from "../../assets/images/moh-logo.png";

import { useState } from "react";
import { validateFields } from "./ValidateFields";
import toast from "react-hot-toast";
import { FieldValidation } from "./RenderFeedback";
import "./style.scss";
import eficaXLogo from "../../assets/wasfaty/brandlogo.svg";

import { SC } from "../wasfaty/Api/serverCall";

const ForgotPasswordV1 = () => {
  const frogetPasswordObj = {
    email: "",
  };
  const frogetPasswordObj2 = {
    email: [],
  };
  const [Data, setData] = useState(frogetPasswordObj);
  const [validation, setValidation] = useState(frogetPasswordObj2);
  const [isValidationError, setIsValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const handlechange = (state, value) => {
    setData({ ...Data, [state]: value });
    handleValidationOnChange({ key: state, value });
  };
  const handleValidationOnChange = ({ key, value }) => {
    const response = validateFields({
      value: value,
      not: true,
      isEmail: key === "email",
    });
    setValidation({ ...validation, [key]: response });
  };
  const handleSubmit = async (data) => {
    data.preventDefault();
    if (loading) return;
    const final = {
      email: Data.email,
    };
    // VO === Validation Object
    let VO = {};
    // validating All required fields
    Object.keys(frogetPasswordObj2).map((item) => {
      const response = validateFields({
        value: final[item],
        not: true,
        isEmail: item === "email",
      });
      if (response?.length > 0) {
        VO = {
          ...VO,
          [item]: response,
        };
      }
    });
    if (Object.keys(VO).length) {
      // set validation
      setValidation(VO);
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      setLoading(true);

      {
        SC.postCall({ url: "forget-password", data: final }).then(
          (response) => {
            if (response?.status === 200 && response.data) {
              toast.success("Email Send successfully!");
              setData(frogetPasswordObj);
              navigate("/check-reset");
            } else {
              toast.error(response?.response?.data?.message);
            }
            setLoading(false);
          }
        );
      }
    }
  };

  return (
    <Container fluid className="authLayout">
      <div className="headercontent">
        <div>
          {/* <img
            src={eficaXLogo}
            width="150px"
            style={{ marginBottom: 10, marginRight: 12 }}
          /> */}
        </div>
        {/* <div>
          <Language />
        </div> */}
      </div>
      <Row className="authLayout__content w-100">
        <Col md="10" lg="5" className="authLayout__formContainer">
          <div className="authLayout__formContainer__box">
            <div className="authLayout__formContainer__box__children">
              <Form onSubmit={handleSubmit}>
                <div>
                  <div className="login-header">
                    <p className="heading-otherscreeens"> Forgot Password?</p>
                    <p className="info">
                      No worries! Please enter the email address and we'll send
                      you password{" "}
                    </p>
                  </div>

                  <div>
                    <div className="mb-1">
                      <Input
                        type="text"
                        className="loginFields"
                        placeholder="Email"
                        value={Data.email}
                        onChange={(e) => {
                          handlechange("email", e.target.value.toLowerCase());
                        }}
                      />

                      {isValidationError && (
                        <FieldValidation
                          validations={validation.email}
                          isTransparent={true}
                        />
                      )}
                    </div>
                  </div>

                  <Button
                    type="submit"
                    color="primary button-main"
                    block
                    className="p-1 authButton"
                  >
                    {!loading ? (
                      "Send reset link"
                    ) : (
                      <>
                        <Spinner size="sm" />
                        <span>Submiting...</span>
                      </>
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="authLayout__footer">
        {" "}
        <a
          href="https://ascend.com.sa/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={PoweredByLogo}
            alt="logo"
            className="authLayout__footer--logo"
          />
        </a>
      </Row>
    </Container>
  );
};

export default ForgotPasswordV1;
