import { lazy } from "react";
import DeparmentForm from "../../views/pages/Add/Department/Deparment";
import Diseases from "../../views/pages/Add/Diseases/Diseases";
import NotificationsAdd from "../../views/pages/Add/NotificatiosForm";
import PublicEventsAdd from "../../views/pages/Add/PublicEventsForm";
const EventType = lazy(() =>
  import("../../views/pages/Add/EventType/EventType")
);

const Modules = lazy(() => import("../../views/pages/Add/Modules/Modules"));

const Regions = lazy(() => import("../../views/pages/Add/Regions/Regions"));
const Translation = lazy(() =>
  import("../../views/pages/Add/Translation/Translation")
);
const Sites = lazy(() => import("../../views/pages/Add/Sites/Sites"));
const Users = lazy(() => import("../../views/pages/Add/User/User"));
const Roles = lazy(() => import("../../views/pages/Add/Role/Role"));
const Permissions = lazy(() => import("../../views/pages/Add/Role/Permission"));
const Multi_Sites = lazy(() =>
  import("../../views/pages/Add/Multi-Sites Form/index")
);

const Multi_Sites_Add = lazy(() =>
  import("../../views/pages/Add/Multi-Sites Form/MultisiteAdd")
);

// %templateImport%
const OutbreakInformation = lazy(() =>
  import("../../views/pages/Add/OutbreakInformation")
);

const AddRoutes = [
  {
    element: <Users />,
    path: "/Users/Add",
    default: true,
    route: "users",
    slug: "users",
    title: "users",
    isForm: true,
  },
  {
    element: <Roles />,
    path: "/Roles/Add",
    default: true,
    route: "roles",
    slug: "roles",
    title: "roles",
    isForm: true,
  },
  {
    element: <Roles key="edit" />,
    path: "/Roles/Edit/:id",
    // default: true,
    route: "roles",
    slug: "roles",
    title: "roles",
    isForm: true,
  },
  {
    element: <Permissions />,
    path: "/Roles/Permissions/:id",
    default: true,
    route: "permissions",
    slug: "permissions",
    title: "permissions",
    isForm: true,
  },
  {
    element: <DeparmentForm />,
    path: "/Departments/Add",
    default: true,
    route: "departments",
    slug: "departments",
    title: "departments",
    isForm: true,
  },
  {
    element: <DeparmentForm key="edit" />,
    path: "/Departments/Edit/:id",
    // default: true,
    route: "departments",
    slug: "departments",
    title: "departments",
    isForm: true,
  },

  {
    element: <Modules />,
    path: "/Modules/Add",
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },
  {
    element: <Modules key="editmodules" />,
    path: "/Modules/Edit/:id",
    permissions: ["admin"],
    route: "Modules",
    slug: "Modules",
    title: "Modules",
    isForm: true,
  },

  {
    element: <Regions />,
    path: "/Regions/Add",
    route: "Add Regions",
    slug: "Regions",
    title: "Add Regions",
    isForm: true,
  },
  {
    element: <Regions key="editregions" />,
    path: "/Regions/Edit/:id",
    route: "Regions",
    slug: "Regions",
    title: "Regions",
    isForm: true,
  },
  {
    element: <Diseases />,
    path: "EventType/Add",
    route: "EventType",
    slug: "EventType",
    title: "EventType",
    isForm: true,
  },
  {
    element: <Diseases key="editdiseases" />,
    path: "/EventType/Edit/:id",
    route: "EventType",
    slug: "EventType",
    title: "EventType",
    isForm: true,
  },
  {
    element: <Translation />,
    path: "/Translation",
    route: "Translation",
    slug: "Translation",
    title: "Translation",
    isForm: true,
  },
  {
    element: <Sites />,
    path: "Sites/Add",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
    isForm: true,
  },
  {
    element: <EventType />,
    path: "Events/Add",
    route: "Add Event",
    slug: "Events",
    title: "Events",
    isForm: true,
  },
  {
    element: <EventType />,
    path: "Diseases/Edit/:id",
    route: "Event",
    slug: "Event",
    title: "Event",
    isForm: true,
  },
  {
    element: <Sites key="editsites" />,
    path: "/Sites/Edit/:id",
    route: "Sites",
    slug: "Sites",
    title: "Sites",
    isForm: true,
  },

  {
    element: <Users key={"UsersEdit"} />,
    path: "Users/Edit/:id",
    route: "Users",
    slug: "Users",
    title: "Users",
    isForm: true,
  },

  {
    element: <OutbreakInformation />,
    path: "/Outbreak-717/Add",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
    isForm: true,
  },
  {
    element: <Multi_Sites key={"tasks-route-multi"} />,
    path: "/multi-sites-outbreak",
    route: "multi-sites-outbreak",
    slug: "Multi-Site-Outbreak",
    title: "Multi-Site Events",
    isForm: true,
  },
  {
    element: <Multi_Sites_Add key={"tasks-route-multi-add"} />,
    path: "/multi-sites-outbreak/Add",
    route: "multi-sites-outbreak",
    slug: "Multi-Site-Outbreak",
    title: "Multi-Site Events",
    isForm: true,
  },
  {
    element: <NotificationsAdd key={"NotificationsAdd-add"} />,
    path: "/Notifications/Add",
    route: "Notifications",
    slug: "Notifications",
    title: "Notifications",
    isForm: true,
  },
  {
    element: <NotificationsAdd key={"NotificationsAdd-edot"} />,
    path: "/Notifications/edit/:id",
    route: "Notifications",
    slug: "Notifications",
    title: "Notifications",
    isForm: true,
  },
  {
    element: <Multi_Sites_Add key={"tasks-route-multi-add"} />,
    path: "/multi-sites-outbreak/Edit/:id",
    route: "multi-sites-outbreak",
    slug: "Multi-Site-Outbreak",
    title: "Multi-Site Events",
    isForm: true,
  },

  {
    element: <PublicEventsAdd key={"Public-EventsAdd-add"} />,
    path: "/Public-Events/Add",
    route: "Public-Events",
    slug: "Public-Events",
    title: "Public-Events",
    isForm: true,
  },
  {
    element: <PublicEventsAdd key={"Public-Events-edot"} />,
    path: "/Public-Events/edit/:id",
    route: "Public-Events",
    slug: "Public-Events",
    title: "Public-Events",
    isForm: true,
  },
  {
    element: <OutbreakInformation key={"tasks-route"} />,
    path: "/Outbreak-717/Edit/:id",
    route: "Outbreak-717",
    slug: "Outbreak-717",
    title: "Outbreak Information Form",
    isForm: true,
  },

  // %template%
];
export default AddRoutes;

export const defaultRoutes = {
  user: Users,
};
