import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Row, Col, Card, CardBody } from "reactstrap";
import UserService from "../../wasfaty/services//UserService";
//  Card Header Pictures
import Download from "../../../assets/images/Download.png";
import Printer from "../../../assets/images/Printer.png";
import User from "../../../assets/images/user.png";
import { FormattedMessage, useIntl } from "react-intl";
import startCase from "lodash/startCase";
import { getUserData } from "@utils";

import "./style.scss";
import { UserModal } from "../../wasfaty/Models/UserModal";

function UserDetails({ user }) {
  const [item, setItem] = useState(user);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const intl = useIntl();
  const authUser = getUserData();
  const isAdmin = authUser?.type === "admin";

  useEffect(() => {
    if (id && !user) {
      UserService.show(id).then((res) => {
        let m = new UserModal(res.data?.data);
        setItem(m.getReview());
        setLoading(false);
      });
    }
  }, [id]);
  if (!item) return null;
  return (
    <>
      <Row id="user-details">
        <Col>
          <Card className="mb-4 shadow-none">
            <nav
              className="navbar navbar-expand-md "
              style={{ backgroundColor: "#04244A", color: "white" }}
            >
              <div className="container-fluid">
                <Link
                  className="navbar-brand"
                  to="#"
                  style={{ color: "white", fontWeight: "500" }}
                >
                  <FormattedMessage
                    id="User Details"
                    defaultMessage="User Details"
                  />
                </Link>

                {/* <div>
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        aria-current="page"
                        to="#"
                      >
                        <img src={Download} className="img-fluid w-75" alt="" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="#">
                        <img src={Printer} className="img-fluid w-75" alt="" />
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </nav>
            <CardBody className="card_body">
              <div className="main_card">
                {!isLoading ? (
                  <Row>
                    <Col md="3" sm="12">
                      <img src={User} className="img-fluid" alt="" />
                    </Col>
                    <Col md="9" sm="12">
                      <ItemRow textFor="Name" text={item.name} />
                      <ItemRow textFor="Email" text={item.email} />
                      <ItemRow
                        textFor="Phone No#"
                        text={item.phone}
                        isLtr={true}
                      />
                      {item.region_role_ids.map((item, index) => (
                        <div>
                          <ItemRow
                            textFor={`Role ${index + 1}`}
                            text={item.label}
                            isLtr={true}
                          />

                          {/* <ItemRow
                            textFor={`Region ${index + 1}`}
                            text={item.region.region_name_en}
                            isLtr={true}
                          /> */}
                        </div>
                      ))}
                      {/* <ItemRow textFor="Role" text={startCase(item.selectedRole?.label)} />
                      {item.type != "admin" && (
                        <ItemRow
                          textFor="Health Directorate"
                          text={item.region}
                        />
                      )} */}

                      {/* {item.type != "regional representatives" &&
                        item.type != "admin" && (
                          <ItemRow textFor="Site" text={item.site} />
                        )} */}

                      {/* <ItemRow textFor="Cluster" text={item.cluster} /> */}
                      {/* <ItemRow textFor="Module" text={item.module} /> */}
                      {/* <ItemRow textFor="Category" text={item.category} /> */}
                    </Col>
                  </Row>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default UserDetails;

const ItemRow = ({ textFor = "", text = "", isLtr = false }) => {
  return (
    <Row>
      <Col md="3">
        <p className="heading">
          <FormattedMessage id={textFor} defaultMessage={textFor} /> {" : "}
        </p>
      </Col>
      <Col style={{ padding: 0 }} {...(isLtr && { dir: "ltr" })}>
        <span className="item-value">
          {text && text?.length ? text : "N/A"}
        </span>
      </Col>
    </Row>
  );
};
