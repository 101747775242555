import { Singleton, Resources } from "../Resource";
import get from "lodash/get";

import moment from "moment";
import ModuleService from "./ModuleService";
import { IdentificationColumns } from "../Helper/PdfObject";
class Service extends Resources {
  route = "outbreak";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  ranking = {};

  constructor() {
    super(arguments);
  }

  getMaxnumber(users, number) {
    if (users?.length > number) {
      return users.length;
    }

    return number;
  }

  get columns() {
    return ModuleService.getFormHeaders("Outbreak-717");
  }

  getHeaders(obj, maxUsers) {
    let usersHeaders = [];
    for (let index = 1; index <= maxUsers; index++) {
      usersHeaders.push(
        {
          title: `Inspector ${index} ` + "name",
          dataIndex: `inspector_${index}_name`,
        },
        {
          title: `Inspector ${index} ` + "email",
          dataIndex: `inspector_${index}_email`,
        }
      );
    }
    return [...IdentificationColumns, ...this.columns, ...usersHeaders];
  }

  mapExportData(data) {
    let maxUsers = 0;
    try {
      data = data.map((item) => {
        item.region = get(item, "region.name", "");
        item.site = (item.site?._id ? item.site : item.obligation_site) || {};

        let name_en = item.site?.name;
        let name_ar = item.site?.name_ar;
        let name = name_ar || name_en || "N/A";
        if (name_en && name_ar) {
          name = name_en + " - " + name_ar;
        }

        item.site = name;

        item.user = get(item, "user.name", "");
        if (item.inspectors?.length) {
          item.inspectors.forEach((inspector, i) => {
            item[`inspector_${i + 1}_name`] = inspector.name;
            item[`inspector_${i + 1}_email`] = inspector.email;
          });
        }
        item.created_at = item?.created_at
          ? moment(item?.created_at).format("lll")
          : "";
        item.date_of_emergence = item?.date_of_emergence
          ? moment(item?.date_of_emergence).format("lll")
          : "";
        item.first_reported_case = item?.first_reported_case
          ? moment(item?.first_reported_case).format("lll")
          : "";

        maxUsers = this.getMaxnumber(item.inspectors, maxUsers);

        return item;
      });
    } catch (error) {
      console.log("OutbreakInformation export error: ", error);
    }
    return {
      headers: this.getHeaders(data[0], maxUsers),
      data,
    };
  }
}

export default Singleton(Service);
