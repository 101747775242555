import { useState } from "react";

import { Card, CardBody, Col, Row, UncontrolledTooltip } from "reactstrap";

import { observer } from "mobx-react";

import moment from "moment";
import { SC } from "../../wasfaty/Api/serverCall";
import { IntlService } from "../../wasfaty/services";
import TotalRecords from "../List/components/TotalRecords";

import { useNavigate } from "react-router-dom";
import AddIcon from "../../../assets/wasfaty/addFormsvg.svg";
import List from "../../wasfaty/components/listing/List";
import { MoreVertical } from "react-feather";
import DropdownB from "../../wasfaty/Helper/DropdownB";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import { getUserData } from "@utils";

const MySwal = withReactContent(Swal);
const PublicEventsList = observer(({}) => {
  const user = getUserData();
  const type = user.type?.toLowerCase();
  const selectedRole = getUserData()?.roles?.find(
    (item) => getUserData().type === item.name.toLowerCase()
  );
  const canAdd =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-create-publicEvent"
    ) || type === "admin";
  const canView =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-view-publicEvent"
    ) || type === "admin";
  const canEdit =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-update-publicEvent"
    ) || type === "admin";
  const canDelete =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-delete-publicEvent"
    ) || type === "admin";
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();

  const [multiSitesListing, setMultiSitesListing] = useState([]);
  const [pagination, setpagination] = useState({
    page: 1,
    pageCount: 0,
    to: 0,
    totalPages: 0,
  });
  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const getNotifications = (query = { selected: 0 }, filters = null) => {
    const params = {
      page: query.selected + 1,
      per_page: 10,
      filters,
      ...filter,
    };
    SC.getCall({
      url: `publicEvent`,
      params,
    }).then((res) => {
      if (res?.status === 200 && res?.data?.data) {
        setMultiSitesListing(res.data.data?.data);
        const _pagination = res.data.data;
        const page = _pagination.current_page;
        const perpage = _pagination.per_page;
        const totalPages = _pagination.total;
        const pageCount = Math.ceil(totalPages / perpage);

        const to = _pagination.to;

        setpagination({
          page,
          pageCount,
          totalPages,
          to,
        });
      }
    });
  };

  const basicColumns = [
    {
      name: "Name",
      // sortable: true,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">{row?.name}</span>
        );
      },
    },
    {
      name: "Health Directorate",
      // sortable: true,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {row?.health_directorate.name}
          </span>
        );
      },
    },
    {
      name: "created at",
      // sortable: true,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            <span>{`${moment(row.created_at).format("ll")} `}</span>
          </span>
        );
      },
    },
    {
      name: "ACTION",
      sortable: false,
      cell: (row) => (
        <MoreVertical
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={(e) => {
            setSelectedRow({ ...row, clientX: e.clientX, clientY: e.clientY });
          }}
        />
      ),
    },
  ];

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        SC.deleteCall({ url: `publicEvent/delete/${id}` }).then((res) => {
          if (res.status === 200) {
            getNotifications({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };
  const filteredColumns = basicColumns.filter((column) => {
    if (column.name === "ACTION") {
      return canEdit || canDelete;
    }
    return true;
  });
  return (
    <Row className="Main-class">
      {/* multi sites listing */}
      {!!selectedRow && (
        <DropdownB
          size={20}
          row={selectedRow}
          isDetails={false}
          DeleteData={DeleteData}
          onEdit={(id) => navigate("/Public-Events/edit/" + id)}
          isEdit={canEdit}
          isDelete={canDelete}
          onClose={() => setSelectedRow(null)}
        />
      )}
      <Card className="shadow-none m-0 mb-1">
        <CardBody style={{ pading: 20 }}>
          <Row>
            <TotalRecords
              md="3"
              flex=""
              title={"Public Events"}
              number={pagination?.totalPages}
            />
            <Col md="9" sm="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  flexWrap: "wrap",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {canAdd ? (
                  <div
                    style={{ marginLeft: 10 }}
                    className="cursor-pointer"
                    onClick={() => navigate("/Public-Events/Add")}
                    id="add"
                  >
                    <img src={AddIcon} alt="AddIcon" />
                  </div>
                ) : (
                  <div id="add"></div>
                )}
              </div>

              <UncontrolledTooltip placement="top" target="add">
                {IntlService.m("Add New")}
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {/* {canView && ( */}
      <List
        basicColumns={filteredColumns}
        // Mock={Mock}
        Mock={multiSitesListing}
        pagination={pagination}
        handlePageChange={getNotifications}
      />
      {/* )} */}
    </Row>
  );
});

export default PublicEventsList;
