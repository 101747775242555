import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import Breadcrumbs from "@components/breadcrumbs";
import List from "../../wasfaty/components/listing/List";
import Mock from "../../../Mock";
import Dropdown from "../../wasfaty/Helper/DropdownB";
import { SC } from "../../components/wasfatyComp/Api/serverCall";
import { IntlService } from "../../wasfaty/services";
import ExportExcel from "../../components/ExportExcel";
import AddIcon from "../../../assets/wasfaty/addFormsvg.svg";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getUserData } from "@utils";
import { MoreVertical } from "react-feather";
import get from "lodash/get";
import TotalRecords from "./components/TotalRecords";

const MySwal = withReactContent(Swal);
import SearchComponent from "./SearchComponent";
import DiseaseService from "../../wasfaty/services/DiseaseService";

function DiseaseList() {
  const navigate = useNavigate();
  const type = getUserData().type?.toLowerCase();
  const selectedRole = getUserData()?.roles?.find(
    (item) => getUserData().type === item.name.toLowerCase()
  );
  const canAdd =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-create-eventType"
    ) || type === "admin";
  const canView =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-view-eventType"
    ) || type === "admin";
  const canEdit =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-update-eventType"
    ) || type === "admin";
  const canDelete =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-delete-eventType"
    ) || type === "admin";
  const [selectedRow, setSelectedRow] = useState();
  const searchRef = useRef();

  const [items, setItems] = useState([]);
  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    to: 0,
    totalPages: 0,
  });

  const getAllItems = (query = { selected: 0, isFromSearch: false }) => {
    const params = {
      page: (query.selected || 0) + 1,
      per_page: 10,
      search: searchRef?.current?.searchText || "",
    };

    // if (query.isFromSearch) {
    //   searchRef?.current?.setSearchLoading?.(true);
    // }

    DiseaseService.find(params).then((res) => {
      if (res?.data?.data) {
        setItems(res.data?.data);
        // searchRef?.current?.setSearchLoading?.(false);

        const _pagination = res.data;
        const page = _pagination?.current_page;
        const perpage = _pagination?.per_page;
        const totalPages = _pagination?.total;
        const pageCount = Math.ceil(totalPages / perpage);

        const to = _pagination?.to;

        setpagination({
          page,
          pageCount,
          totalPages,
          to,
        });
      }
    });
  };
  const showData = (e) => {
    navigate("/Region/Details/" + e);
  };
  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        DiseaseService.delete(id).then((res) => {
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };
  const basicColumns = [
    {
      name: "NAME",
      // sortable: true,
      width: "90%",
      cell: (row) => <span>{get(row, "disease_name_en") || "N/A"}</span>,
    },
    {
      name: "ACTION",
      sortable: false,
      cell: (row) => (
        <MoreVertical
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={(e) => {
            setSelectedRow({
              ...row,
              clientX: e.clientX,
              clientY: e.clientY,
            });
          }}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Card className="shadow-none m-0 mb-1">
        <CardBody style={{ pading: 20 }}>
          <Row>
            {!!selectedRow && (
              <Dropdown
                size={20}
                row={selectedRow}
                DeleteData={DeleteData}
                isEdit={canEdit}
                isDelete={canDelete}
                isDetails={false}
                onEdit={(id) => navigate("/EventType/Edit/" + id)}
                onClose={() => setSelectedRow(null)}
              />
            )}
            <TotalRecords
              title={"Event Type List"}
              number={pagination?.totalPages}
              flex=""
              md="3"
            />
            <Col md="9" sm="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 5,
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {canAdd ? (
                  <div
                    style={{ marginLeft: 10 }}
                    className="cursor-pointer"
                    onClick={() => navigate("/EventType/Add")}
                    id="add"
                  >
                    <img src={AddIcon} alt="AddIcon" />
                  </div>
                ) : (
                  <div id="add"></div>
                )}
                <div id="export">
                  {/* <ExportExcel
                    apiCall={true}
                    endPoint={`${DiseaseService.route}-index`}
                    icon={true}
                    fileName={"Diseases"}
                    exportFilter={{ per_page: pagination.totalPages }}
                    handleExportContent={({ data }) =>
                      DiseaseService.mapExportData(data.data || [])
                    }
                  /> */}
                </div>
                <div id="search">
                  <SearchComponent
                    onSearchEnd={getAllItems}
                    ref={searchRef}
                    // onSearchClick={setIsSearchOpend}
                    isVisiable={true}
                  />
                </div>
                <SearchComponent onSearchEnd={getAllItems} ref={searchRef} />
              </div>
              <UncontrolledTooltip placement="top" target="search">
                {IntlService.m("Search")}
              </UncontrolledTooltip>
              {/* <UncontrolledTooltip placement="top" target="export">
                {IntlService.m("Export as Excel")}
              </UncontrolledTooltip> */}
              <UncontrolledTooltip placement="top" target="add">
                {IntlService.m("Add New")}
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* {canView && ( */}
      <List
        basicColumns={basicColumns}
        Mock={items}
        pagination={pagination}
        handlePageChange={getAllItems}
      />
      {/* )} */}
    </Fragment>
  );
}

export default DiseaseList;
