import Select from "react-select";
import { searchFilterIcon, deleteIcon } from "../../../assets/wasfaty/SVG";

const FilterDropDown = ({ options, selectedRegion, onSelect }) => {
  return (
    <div className="search_box">
      <div className="dropdown">Region</div>

      <Select
        classNamePrefix="custom-flter-select"
        options={options}
        value={selectedRegion}
        onChange={(e) => {
          onSelect(e);
        }}
      />

      {selectedRegion ? (
        <figure className="searchIcon" onClick={() => onSelect(null)}>
          {deleteIcon}
        </figure>
      ) : (
        <figure className="searchIcon">{searchFilterIcon}</figure>
      )}
    </div>
  );
};

export default FilterDropDown;
