import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "nupco";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    draftUsers: "nupco/draft/users",
    clusters: "nupco/clusters",
    status: "nupco/status",
    dashboard: "nupco/dashboard",
  };

  constructor() {
    super(arguments);
  }

  draftUsers() {
    return SC.getCall({
      url: this.routes.draftUsers,
    });
  }

  clusters(regionId) {
    return SC.getCall({
      url: this.routes.clusters,
      params: { regionId },
    });
  }

  statusUpdate(id, data) {
    return SC.putCall({
      url: this.routes.status + `/${id}`,
      data,
    });
  }
  getDashboardData() {
    return SC.getCall({
      url: this.routes.dashboard,
      // data,
    });
  }

  getDashboardData(cluster) {
    let params = {
      cluster_id: cluster,
    };
    return SC.getCall({
      url: this.routes.dashboard,
      params,
    });
  }

  getHeaders(obj, maxUsers) {
    if (obj) {
      let usersHeaders = [];
      for (let index = 1; index <= maxUsers; index++) {
        usersHeaders.push(
          {
            title: `${index} ` + "User Type",
            dataIndex: `${index} ` + "user_type",
          },
          {
            title: `${index} ` + "National ID/Iqama",
            dataIndex: `${index} ` + "national_id",
          },
          {
            title: `${index} ` + startCase("community_portal_user"),
            dataIndex: `${index} ` + "community_portal_user",
          },
          {
            title: `${index} ` + startCase("specialty_level"),
            dataIndex: `${index} ` + "specialty_level",
          },
          {
            title: `${index} ` + startCase("medical_specialization"),
            dataIndex: `${index} ` + "medical_specialization",
          },
          {
            title: `${index} ` + startCase("specialty_description_eng"),
            dataIndex: `${index} ` + "practitioners_specialty_description_eng",
          },
          {
            title: `${index} ` + startCase("specialty_description_arb"),
            dataIndex: `${index} ` + "practitioners_specialty_description_arb",
          },
          {
            title: `${index} ` + startCase("practitioners_in_call"),
            dataIndex: `${index} ` + "practitioners_in_call",
          },
          {
            title: `${index} ` + startCase("medical_license_for_practitioners"),
            dataIndex: `${index} ` + "medical_license_for_practitioners",
          }
        );
      }
      return [
        { title: "User", dataIndex: "user" },
        { title: "Status", dataIndex: "status_of_the_submission" },
        { title: "Reject Message", dataIndex: "reject_message" },
        { title: "Region", dataIndex: "region" },
        { title: "Cluster", dataIndex: "cluster" },

        {
          title: startCase("facility_name_english"),
          dataIndex: "facility_name_english",
        },
        {
          title: startCase("facility_name_arabic"),
          dataIndex: "facility_name_arabic",
        },
        { title: startCase("city"), dataIndex: "city" },

        { title: startCase("facility_type"), dataIndex: "facility_type" },
        {
          title: startCase("facility_sub_type"),
          dataIndex: "facility_sub_type",
        },
        { title: startCase("moh_id"), dataIndex: "moh_id" },
        {
          title: startCase("nhic_organization_id"),
          dataIndex: "nhic_organization_id",
        },
        { title: startCase("wasfaty_code"), dataIndex: "wasfaty_code" },

        { title: startCase("longitude"), dataIndex: "longitude" },
        { title: startCase("wasfaty_status"), dataIndex: "wasfaty_status" },
        { title: startCase("his_system"), dataIndex: "his_system" },
        {
          title: startCase("type_of_his_system_used"),
          dataIndex: "type_of_his_system_used",
        },
        { title: startCase("pharmacy_ten_km"), dataIndex: "pharmacy_ten_km" },
        {
          title: startCase("internal_pharmacy_status"),
          dataIndex: "internal_pharmacy_status",
        },
        {
          title: startCase("date_of_closing_internal_pharmacy"),
          dataIndex: "date_of_closing_internal_pharmacy",
        },
        {
          title: startCase("community_pharmacy_chain"),
          dataIndex: "community_pharmacy_chain",
        },
        {
          title: startCase("community_pharmacy_chain_other"),
          dataIndex: "community_pharmacy_chain_other",
        },
        { title: startCase("working_hours"), dataIndex: "working_hours" },
        {
          title: startCase("working_hours_other"),
          dataIndex: "working_hours_other",
        },
        { title: startCase("infrastructure"), dataIndex: "infrastructure" },
        { title: startCase("avg_monthly_opd"), dataIndex: "avg_monthly_opd" },
        {
          title: startCase("building_operation_status"),
          dataIndex: "building_operation_status",
        },
        { title: startCase("weekend_shift"), dataIndex: "weekend_shift" },
        {
          title: startCase("weekend_working_hours"),
          dataIndex: "weekend_working_hours",
        },
        {
          title: startCase("weekend_working_hours_other"),
          dataIndex: "weekend_working_hours_other",
        },
        { title: "Number of Users", dataIndex: "number_of_users" },
        ...usersHeaders,
        { title: "Created At", dataIndex: "created_at" },
      ];
    }

    return [];
  }

  getMaxnumber(users, number) {
    if (users?.length > number) {
      return users.length;
    }

    return number;
  }

  mapExportData(data) {
    let maxUsers = 0;
    try {
      data = data.map((item) => {
        item.region = get(item, "region.name", "");
        item.cluster = get(item, "cluster.name", "");
        item.user = get(item, "user.name", "");

        let status_of_the_submission = "Pending";
        item.reject_message = item.message;
        if (!item.active && item.message) {
          status_of_the_submission = "Rejected";
        }

        if (item.active) {
          status_of_the_submission = "Approved";
          item.reject_message = "";
        }

        item.status_of_the_submission = status_of_the_submission;

        if (item.nupco_site) {
          item.city = item.nupco_site.city;
          item.facility_name_english = item.nupco_site.phc_name_eng;
          item.facility_name_arabic = item.nupco_site.phc_name_ar;
          item.facility_type = item.nupco_site.facilityType;
          item.facility_sub_type = item.nupco_site.facilitySubType;
          item.moh_id = item.nupco_site.moh_id;
          item.nhic_organization_id = item.nupco_site.nhic_organization_id;
          item.longitude = item.nupco_site.longitude;
          item.latitude = item.nupco_site.latitude;
          item.wasfaty_code = item.nupco_site.wasfaty_code;
          item.date_of_closing_internal_pharmacy = moment(
            item.date_of_closing_internal_pharmacy
          ).format("L");

          if (item.date_of_closing_internal_pharmacy === "Invalid date") {
            item.date_of_closing_internal_pharmacy = "";
          }

          if (item.nupco_site?.cluster) {
            item.cluster = item.cluster || item.nupco_site?.cluster.name;
          }
        }

        let a = item.nupco_users.reduce((result, c, cIndex) => {
          Object.entries(c).forEach(([key, value]) => {
            result[`${cIndex + 1} ${key}`] = value;
          });

          return result;
        }, {});

        maxUsers = this.getMaxnumber(item.nupco_users, maxUsers);
        item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");
        return { ...a, ...item };
      });
    } catch (error) {
      console.log("Nupco export error: ", error);
    }

    return {
      headers: this.getHeaders(data[0], maxUsers),
      data,
    };
  }
}

export default Singleton(Service);
