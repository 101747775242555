import React, { forwardRef, useState } from "react";
import { Fragment } from "react-is";
import PaginationComponent from "./Pagination";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { IntlService } from "../../services";

function PaginationFooter({
  handlePageChange,
  pagination = {},
  isStopPaginationFirstCall,
}) {
  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div className="mt-1">
          {!!pagination.totalPages && (
            <p className="list-info">{`${IntlService.m("Showing")} ${
              pagination?.to || 0
            } ${IntlService.m("from")} ${
              pagination?.totalPages
            } ${IntlService.m("data")}`}</p>
          )}
        </div>
        <div className="">
          <PaginationComponent
            pagination={pagination}
            handlePageChange={handlePageChange}
            isStopPaginationFirstCall={isStopPaginationFirstCall}
          />
        </div>
      </div>
    </Fragment>
  );
}

export default observer(PaginationFooter);
