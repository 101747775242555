import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import get from "lodash/get";

class Service extends Resources {
  route = "disease";
  routes = {
    find: "?isPagination=1",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  byMethod = "get-form-diseases";
  byAllDiseases = "diseases";
  diseases = [];

  constructor() {
    super(arguments);
  }

  getByMethod = async (method) => {
    if (this.diseases.length) {
      return this.diseases;
    }

    try {
      let res = await SC.getCall({
        url: this.byMethod,
        params: { method },
      });
      let _diseases = get(res, "data.data");
      if (_diseases?.length) {
        this.diseases = _diseases.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));

        return this.diseases;
      }

      return [];
    } catch (e) {
      return [];
    }
  };

  getAllDiseases() {
    return SC.getCall({
      url: this.byAllDiseases,
    });
  }

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      // { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

const DiseaseService = new Service();

export default DiseaseService;
