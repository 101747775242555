// ** Vertical Menu Components
import VerticalNavMenuLink from "./VerticalNavMenuLink";
import VerticalNavMenuGroup from "./VerticalNavMenuGroup";
import VerticalNavMenuSectionHeader from "./VerticalNavMenuSectionHeader";

// ** Utils
import {
  canViewMenuItem,
  canViewMenuGroup,
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
} from "@layouts/utils";

const VerticalMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader,
  };

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)];
    // console.log("TagName", TagName);
    if (TagName) {
      if (item.children) {
        // console.log("item", item);

        return <TagName item={item} index={index} key={item.id} {...props} />;
      }
      return <TagName key={item.id || item.header} item={item} {...props} />;
    }

    // return <></>;
  });

  return RenderNavItems;
};

export default VerticalMenuNavItems;
