import { Fragment, useState, useRef } from "react";
import {
  Row,
  Col,
  Input,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap";
import List from "../../wasfaty/components/listing/List";
import Dropdown from "../../wasfaty/Helper/DropdownB";
import {
  AuthService,
  IntlService,
  DepartmentService,
} from "../../wasfaty/services";
import ExportExcel from "../../components/ExportExcel";
import get from "lodash/get";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import debounce from "lodash/debounce";
import { useIntl } from "react-intl";
import { MoreVertical } from "react-feather";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import { Spinner } from "reactstrap";
import { useDimensions } from "../../../hooks/useDimensions";
import TotalRecords from "./components/TotalRecords";
import startCase from "lodash/startCase";

import { getUserData } from "@utils";
import AddIcon from "../../../assets/wasfaty/addFormsvg.svg";
const MySwal = withReactContent(Swal);

function DepartmentList() {
  const navigate = useNavigate();
  const intl = useIntl();

  const user = getUserData();
  const type = user.type?.toLowerCase();
  const canEditDelete = type === "admin" || type === "nupco_admin";
  const selectedRole = getUserData()?.roles?.find(
    (item) => getUserData().type === item.name.toLowerCase()
  );
  const canAdd =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-create-department"
    ) || type === "admin";
  const canView =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-view-outbreak-form"
    ) || type === "admin";
  const canEdit =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-update-department"
    ) || type === "admin";
  const canDelete =
    !!selectedRole?.permissions?.find(
      (item) => item?.name === "can-delete-department"
    ) || type === "admin";
  const isNupcoColumns = type === "nupco_admin" || user?.isNupcoUser;
  const [search, setSearch] = useState("");
  const searchRef = useRef();
  searchRef.current = search;
  const [searchLoading, setSearchLoading] = useState(false);
  const { isMobile } = useDimensions();

  const [selectedRow, setSelectedRow] = useState();
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [items, setItems] = useState([]);
  const [pagination, setpagination] = useState({
    page: 0,
    pageCount: 0,
    to: 0,
    totalPages: 0,
    to: 0,
  });

  const getAllItems = (query = { selected: 0, isFromSearch: false }) => {
    const params = {
      page: query.selected + 1,
      per_page: 10,
      search: searchRef.current,
      department717: true,
    };

    setSearchLoading(query.isFromSearch);
    DepartmentService.find(params).then((res) => {
      if (res?.data?.data) {
        setItems(res.data.data.data);

        const _pagination = res.data.data;
        const page = _pagination.current_page;
        const perpage = _pagination.per_page;
        const totalPages = _pagination.total;
        const pageCount = Math.ceil(totalPages / perpage);
        const to = _pagination.to;

        setpagination({
          page,
          pageCount,
          totalPages,
          to,
        });
      }
      setSearchLoading(false);
    });
  };

  const onSearch = useRef(
    debounce(() => {
      getAllItems({ isFromSearch: true });
    }, 1000)
  ).current;

  const showData = (e) => {
    navigate("/Departments/Details/" + e);
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: "<p class='confirm-class-head' >Are you sure you want to delete?</p>",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      buttonsStyling: false,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        DepartmentService.delete(id).then((res) => {
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };

  const basicColumns = [
    {
      name: "NAME",
      sortable: true,
      cell: (row) => {
        return (
          <span className="font-weight-bold text-truncate">
            {get(row, "name", "N/A")}
          </span>
        );
      },
    },
    // {
    //   name: "EMAIL",
    //   sortable: true,
    //   cell: (row) => {
    //     return (
    //       <span className="font-weight-bold text-truncate">
    //         {get(row, "email", "N/A")}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   name: "PHONE",
    //   sortable: true,
    //   cell: (row) => {
    //     return (
    //       <span className="font-weight-bold text-truncate">
    //         {get(row, "phone", "N/A")}
    //       </span>
    //     );
    //   },
    // },

    {
      name: "ACTION",
      sortable: false,
      cell: (row) => (
        <MoreVertical
          style={{ marginLeft: 10, cursor: "pointer" }}
          onClick={(e) => {
            setSelectedRow({ ...row, clientX: e.clientX, clientY: e.clientY });
          }}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <Card className="shadow-none m-0 mb-1">
        <CardBody style={{ pading: 20 }}>
          <Row>
            {!!selectedRow && (
              <Dropdown
                size={20}
                row={selectedRow}
                showData={showData}
                DeleteData={DeleteData}
                onEdit={(id) => navigate("/Departments/Edit/" + id)}
                isEdit={canEdit}
                isDelete={canDelete}
                onClose={() => setSelectedRow(null)}
              />
            )}
            <TotalRecords
              md="3"
              flex=""
              title={"Department List"}
              number={pagination?.totalPages}
            />
            <Col md="9" sm="12">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  flexWrap: "wrap",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                {canAdd ? (
                  <div
                    style={{ marginLeft: 10 }}
                    className="cursor-pointer"
                    onClick={() => navigate("/Departments/Add")}
                    id="add"
                  >
                    <img src={AddIcon} alt="AddIcon" />
                  </div>
                ) : (
                  <div id="add"></div>
                )}
                {/* <div id="export">
                  <ExportExcel
                    style={{
                      marginBottom: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                    apiCall={true}
                    endPoint={`${DepartmentService.route}-index`}
                    fileName={"Users"}
                    exportFilter={{ per_page: pagination.totalPages }}
                    handleExportContent={({ data }) =>
                      DepartmentService.mapExportData(data.data || [])
                    }
                    icon={true}
                  />
                </div> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginTop: isMobile ? 10 : 0,
                  }}
                  id="search"
                >
                  <div>
                    {searchLoading && isSearchClicked && (
                      <Spinner
                        color="primary"
                        style={{
                          height: 18,
                          width: 18,
                          position: "absolute",
                          zIndex: 100,
                          marginLeft: 220,
                          marginTop: 11,
                          background: "white",
                        }}
                      />
                    )}
                    <Input
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onSearch();
                        }
                      }}
                      type="text"
                      className="round"
                      style={{
                        width: 250,
                        display: isSearchClicked ? "block" : "none",
                      }}
                      value={search}
                      placeholder={intl.formatMessage({
                        id: "Search here",
                        defaultMessage: "Search here",
                      })}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        onSearch();
                      }}
                    />
                  </div>

                  <img
                    src={SearchIcon}
                    style={{
                      marginRight: 10,
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setIsSearchClicked((s) => !s)}
                  />
                </div>
              </div>

              <UncontrolledTooltip placement="top" target="search">
                {IntlService.m("Search")}
              </UncontrolledTooltip>
              {/* <UncontrolledTooltip placement="top" target="export">
                {IntlService.m("Export as Excel")}
              </UncontrolledTooltip> */}
              <UncontrolledTooltip placement="top" target="add">
                {IntlService.m("Add New")}
              </UncontrolledTooltip>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* {canView && ( */}
      <List
        basicColumns={basicColumns}
        // Mock={Mock}
        Mock={items}
        pagination={pagination}
        handlePageChange={getAllItems}
      />
      {/* )} */}
    </Fragment>
  );
}

export default DepartmentList;
