import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import get from "lodash/get";

class Service extends Resources {
  route = "regions";
  routes = {
    find: "?isPagination=1",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
  };
  byMethod = "get-form-regions";
  byAllRegions = "regions";
  byUserRegions = "all-region-user";
  setRegion = "user-region";
  regions = [];

  constructor() {
    super(arguments);
  }

  getByMethod = async (method) => {
    if (this.regions.length) {
      return this.regions;
    }

    try {
      let res = await SC.getCall({
        url: this.byMethod,
        params: { method },
      });
      let _regions = get(res, "data.data");
      if (_regions?.length) {
        this.regions = _regions.map((item) => ({
          ...item,
          label: item.name,
          value: item._id,
        }));

        return this.regions;
      }

      return [];
    } catch (e) {
      return [];
    }
  };

  getAllRegions() {
    return SC.getCall({
      url: this.byAllRegions,
    });
  }
  getRegionsByUser() {
    return SC.getCall({
      url: this.byUserRegions,
    });
  }

  setRegionByUser(payload) {
    return SC.postCall({
      url: this.setRegion,
      data: payload,
    }).then((res) => {
      if (res?.status === 200) {
        window.location.href = "/";
      }
    });
  }

  getHeaders() {
    return [
      { title: "Name", dataIndex: "name" },
      // { title: "Created at", dataIndex: "created_at" },
    ];
  }

  mapExportData(data) {
    return {
      headers: this.getHeaders(),
      data,
    };
  }
}

const RegionService = new Service();

export default RegionService;
