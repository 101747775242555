import { Text, View, Canvas } from "@react-pdf/renderer";
import momentHijri from "moment-hijri";
import cloneDeep from "lodash/cloneDeep";
import Staff from "../Models/Staff";
import ArabicContent from "../../../assets/data/locales/sa.json";
import get from "lodash/get";
import startCase from "lodash/startCase";
import Violation from "../Models/Violation";
import MedicineModel from "../Models/MedicineSelectionForMadicineAvailbilityModel";
import moment from "moment";
import DateObject from "react-date-object";
import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_en";

const staffModel = new Staff();
const violationModel = new Violation();
const medModel = new MedicineModel();
export const getPdfObject = (formFields) => {
  const storeAarrayAndReverse = (text) => {
    const _splitedText = text.split(" ").filter((i) => i);
    return _splitedText;
  };

  const RenderArabic = ({ content, style }) => {
    const replacer = (word) => {
      switch (word) {
        case ")":
          return "(";

        case "(":
          return ")";

        default:
          return word;
      }
    };
    return (
      <View style={style}>
        {storeAarrayAndReverse(content).map((word, idx) => {
          return (
            <Text
              style={{
                paddingHorizontal: 4,
              }}
              key={idx}
            >
              {replacer(word)}
            </Text>
          );
        })}
      </View>
    );
  };

  let updatedObj = cloneDeep(formFields);
  delete updatedObj.identification;

  updatedObj = JSON.parse(JSON.stringify(updatedObj));
  Object.entries(updatedObj).forEach(([key, value]) => {
    value.forEach((item) => {
      item.state = item.attribute;
      item.moreOptions = true;
    });
  });

  if (updatedObj["staff_availability"]) {
    updatedObj["staff_availability"] = [
      {
        title: "Staff Availability",
        state: "staff_availability",
        function: (data) => {
          return (
            <View>
              {data.length &&
                data.map((item, index) => (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      fontSize: 12,
                      marginVertical: 10,
                      fontFamily: "Helvetica",
                      textAlign: "left",
                    }}
                    key={index}
                  >
                    <Text style={{ fontSize: 14 }}>Staff #{index + 1}</Text>

                    <View
                      style={{
                        borderBottom: "1.2px dashed #D1D1D1",
                        padding: "10px 0px",
                      }}
                    >
                      {staffModel.pdfObject.map((ite, index) => {
                        let optionExixst = get(item, ite.attribute);
                        if (optionExixst)
                          return (
                            <View key={index}>
                              <View
                                style={{
                                  // backgroundColor: "red",
                                  borderColor: "black",
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  padding: 4,
                                  margin: 4,
                                }}
                              >
                                <View
                                  style={{
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Text
                                    style={{
                                      width: "49%",
                                    }}
                                  >
                                    {ite.title}
                                  </Text>

                                  <RenderArabic
                                    content={ArabicContent[ite.title] || ""}
                                    style={{
                                      width: "49%",
                                      display: "flex",
                                      flexDirection: "row-reverse",
                                      fontSize: 10,
                                      fontFamily: "Cairo Regular",
                                      flexWrap: "wrap",
                                    }}
                                  />
                                </View>
                                <Text
                                  style={{
                                    textAlign: "right",
                                    marginBottom: "5",
                                    marginRight: 10,
                                  }}
                                >
                                  {optionExixst}
                                </Text>
                              </View>
                            </View>
                          );
                      })}
                    </View>
                  </View>
                ))}
            </View>
          );
        },
      },
    ];
  }

  if (updatedObj["violation"]) {
    updatedObj["Violation"] = [
      {
        title: "Violations",
        state: "violations",
        function: (data) => {
          return (
            <View>
              {data.map((item, index) => (
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    fontSize: 12,
                    marginVertical: 10,
                    fontFamily: "Helvetica",
                    textAlign: "left",
                  }}
                  key={index}
                >
                  <Text style={{ fontSize: 14 }}>Violation #{index + 1}</Text>

                  <View
                    style={{
                      borderBottom: "1.2px dashed #D1D1D1",
                      padding: "10px 0px",
                    }}
                  >
                    {violationModel.pdfObject.map((ite, index) => {
                      let optionExixst = get(item, ite.attribute);
                      if (optionExixst)
                        return (
                          <View key={index}>
                            <View
                              style={{
                                // backgroundColor: "red",
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 5,
                                padding: 4,
                                margin: 4,
                              }}
                            >
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                }}
                              >
                                <Text
                                  style={{
                                    width: "49%",
                                  }}
                                >
                                  {ite.title}
                                </Text>

                                <RenderArabic
                                  content={ArabicContent[ite.title] || ""}
                                  style={{
                                    width: "49%",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    fontSize: 10,
                                    fontFamily: "Cairo Regular",
                                    flexWrap: "wrap",
                                  }}
                                />
                              </View>
                              <Text
                                style={{
                                  textAlign: "right",
                                  marginBottom: "5",
                                  marginRight: 10,
                                }}
                              >
                                {optionExixst}
                              </Text>
                            </View>
                          </View>
                        );
                    })}
                  </View>
                </View>
              ))}
            </View>
          );
        },
      },
    ];
  }
  if (updatedObj["medicine_section"]) {
    updatedObj["medicine_section"] = [
      {
        title: "Medicine Section",
        state: "medicine",
        function: (data = [], tag, RenderArabic, RenderFile) => {
          return (
            <View>
              {data.map((item, index) => (
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    fontSize: 12,
                    marginVertical: 10,
                    fontFamily: "Helvetica",
                    textAlign: "left",
                  }}
                  key={index}
                >
                  <Text style={{ fontSize: 14 }}>Medicine# {index + 1}</Text>

                  <View
                    style={{
                      borderBottom: "1.2px dashed #D1D1D1",
                      padding: "10px 0px",
                    }}
                  >
                    {medModel.exportObj.map((ite, index) => {
                      let optionExixst = get(item, ite.attribute);
                      let moreOpFile = get(item, `${ite.attribute}_file`)
                        ?.length
                        ? get(item, `${ite.attribute}_file`)
                        : get(item, `${ite.attribute}_file`)?.images;
                      if (optionExixst)
                        return (
                          <View key={index}>
                            <View
                              style={{
                                // backgroundColor: "red",
                                borderColor: "black",
                                borderWidth: 1,
                                borderRadius: 5,
                                padding: 4,
                                margin: 4,
                              }}
                            >
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                }}
                              >
                                <Text
                                  style={{
                                    width: "49%",
                                  }}
                                >
                                  {ite.title}
                                </Text>

                                <RenderArabic
                                  content={ArabicContent[ite.title] || ""}
                                  style={{
                                    width: "49%",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    fontSize: 10,
                                    fontFamily: "Cairo Regular",
                                    flexWrap: "wrap",
                                  }}
                                />
                              </View>
                              <Text
                                style={{
                                  textAlign: "right",
                                  marginBottom: "5",
                                  marginRight: 10,
                                }}
                              >
                                {ite.title == "Reporting Date" ||
                                ite.title == "Issue Date"
                                  ? moment(optionExixst).format("YYYY/M/D")
                                  : optionExixst}
                              </Text>
                              {ite.moreOp && (
                                <>
                                  {get(item, `${ite.attribute}_other`) && (
                                    <>
                                      <Text>Comments:</Text>
                                      <Text
                                        style={{
                                          fontFamily: "Cairo Regular",
                                        }}
                                      >
                                        {get(item, `${ite.attribute}_other`)}
                                      </Text>
                                    </>
                                  )}
                                  {moreOpFile && (
                                    <>
                                      <Text>Files:</Text>

                                      <RenderFile files={moreOpFile} />
                                    </>
                                  )}
                                </>
                              )}
                            </View>
                          </View>
                        );
                    })}
                  </View>
                </View>
              ))}
            </View>
          );
        },
      },
    ];
  }

  if (updatedObj["medical_mission"]) {
    updatedObj["medical_mission"] = medicalMissionObj;
  }

  return {
    identification: identification,
    ...updatedObj,
  };
};

const identification = [
  {
    title: "Inspector Name",
    state: "user.name",
  },
  // {
  //   title: "Inspector email",
  //   state: "auditor_email",
  // },
  // {
  //   title: "Inspector phone number#",
  //   state: "auditor_phone",
  // },
  {
    title: "Region",
    state: "region.name",
  },
  {
    title: "Site",
    state: "site",
    function: (site, state, RenderArabic) => {
      let name_en = site.name;
      let name_ar = site?.name_ar;
      let name = name_ar || name_en || "N/A";
      if (!RenderArabic) {
        return <Text style={{ fontFamily: "Cairo Regular" }}>{name}</Text>;
      }

      return (
        <RenderArabic
          content={name}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap",
          }}
        />
      );
    },
  },
  {
    title: "City",
    state: "city",
  },
  {
    title: "Street name",
    state: "street_name",
  },
  {
    title: "District Name",
    state: "neighborhood",
  },
  {
    title: "Reason for visiting",
    state: "reason_for_visiting",
  },

  {
    title: "Longitude",
    state: "longitude",
  },
  {
    title: "Latitude",
    state: "latitude",
  },
  {
    title: "Is the institution available on the ground?",
    state: "available_on_ground",
  },
  {
    title: "Is the institution available on the ground?",
    state: "is_the_pharmacy_available",
  },
  {
    title:
      "Is the wasfaty service available at all times during pharmacy working hours?",
    state: "wasfaty_service_available",
  },
  {
    title: "Does the pharmacy system connected to RASSD system?",
    state: "pharmacy_connected_to_rased_system",
  },
  {
    title: "Is the RASSD system active at the pharmacy?",
    state: "is_the_rassd_system_active_at_the_pharmacy",
  },
  {
    title: "Does the pharmacist put the instructions label on the medication?",
    state: "medication_instruction_label",
  },
  {
    title: "The name of the owner of the company",
    state: "owner_name",
  },
  {
    title: "The full name of the institution",
    state: "name_of_the_institution",
  },
  {
    title: "Institution email",
    state: "institution_email",
  },
  {
    title: "Is the facility CBAHI certified ?",
    state: "cbahi_certified",
    moreOptions: true,
  },
  {
    title: "Facility license number",
    state: "facility_license_number",
  },
  {
    title: "License issue date",
    state: "license_issue_date",
    function: (data) => {
      let _date = new DateObject({
        date: data,
        calendar: arabic,
        locale: arabic_ar,
      });
      return <Text>{_date.format("YYYY-MM-DD")}</Text>;
    },
  },
  {
    title: "License expiry date",
    state: "license_expiry_date",
    function: (data) => {
      let _date = new DateObject({
        date: data,
        calendar: arabic,
        locale: arabic_ar,
      });
      return <Text>{_date.format("YYYY-MM-DD")}</Text>;
    },
  },
  {
    title: "The name of the managing director of the facility",
    state: "name_of_director",
  },
  {
    title: "Managing director qualification",
    state: "director_qualification",
  },
  {
    title: "The name of the technical supervisor of the facility",
    state: "name_of_technical_supervisor",
  },
  {
    title: "Technical supervisor specialty",
    state: "technical_supervisor_specialty",
  },
  {
    title: "Is the specialization classified by the authority?",
    state: "is_specialization_classified",
    moreOptions: true,
  },

  {
    title: "Notes",
    state: "identification_notes",
  },
  {
    title: "Attachment",
    state: "identification_file",
  },
  {
    title: "Attachment",
    state: "attachments",
  },
  {
    title: "Inspectors",
    state: "inspectors",
    function: (inspectors) =>
      inspectors.map((inspector, index) => (
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            fontSize: 12,
            marginVertical: 10,
            fontFamily: "Helvetica",
          }}
          key={index}
        >
          <Text>Inspector# {index + 1}</Text>
          <Text style={{ fontFamily: "Cairo Regular" }}>
            Name : {inspector.name}
          </Text>
          {/* <Text>Phone : {inspector.phone}</Text> */}
          <View style={{ overflow: "hidden" }}>
            <Canvas
              style={{ marginRight: 100, width: "90%" }}
              paint={(painter) => {
                let l = painter.lineWidth(2);
                inspector.signature.forEach((sig, i) => {
                  sig.forEach((point, i) => {
                    if (i === 0) {
                      l.moveTo(point.x - point.x * 0.2, point.y);
                    } else {
                      l.lineTo(point.x - point.x * 0.2, point.y);
                    }
                  });
                });
                l.stroke("black");
              }}
            />
          </View>
        </View>
      )),
  },
];

export const IdentificationColumns = [
  { title: "User", dataIndex: "user" },
  { title: "Region", dataIndex: "region" },
  { title: "Site", dataIndex: "site" },
  { title: "License", dataIndex: "license" },
  { title: startCase("longitude"), dataIndex: "longitude" },
  { title: startCase("latitude"), dataIndex: "latitude" },
  // { title: "Date of Incident", dataIndex: "date_of_incident" },
  // { title: "Location of Incident", dataIndex: "location_of_incident" },
  // { title: "Description of Incident", dataIndex: "description_of_incident" },
  { title: "Event", dataIndex: "event" },
  { title: "Date of emergence", dataIndex: "date_of_emergence" },
  { title: "First reported case", dataIndex: "first_reported_case" },
];

const medicalMissionObj = [
  {
    title: "Day",
    state: "medical_mission_day",
  },
  {
    title: "Time",
    state: "medical_mission_time",
  },
  {
    title: "Date",
    state: "medical_mission_date",
    function: (data) => {
      return <Text>{moment(data).format("YYYY-MM-DD")}</Text>;
    },
  },
  {
    title: "Medical mission name",
    state: "medical_mission_name",
  },
  {
    title: "Medical mission type",
    state: "medical_mission_type",
  },
  {
    title: "Country",
    state: "medical_mission_country",
  },
  {
    title: "The number of hajji",
    state: "the_number_of_hajji",
  },
  {
    title: "Delegate name",
    state: "delegate_name",
  },
  {
    title: "Name of the medical officer",
    state: "name_of_the_medical_officer",
  },
  {
    title: "E-mail",
    state: "medical_mission_email",
  },
  {
    title: "Contact number",
    state: "contactnumber",
  },
];
