import React, { useState, useEffect, useContext } from "react";
import { selectThemeColors } from "@utils";
import Select from "react-select";
import {
  RegionService,
  ModuleService,
  SiteService,
  NupcoService,
  IntlService,
  DepartmentService,
} from "../../../wasfaty/services";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";

import { DepartmentModal } from "../../../wasfaty/Models/DepartmentModal";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
import { IntlContext } from "../../../../utility/context/IntelContext";
import { FormattedMessage, useIntl } from "react-intl";
import UserDetails from "../../Show/UserDetails";
import { useParams, useNavigate } from "react-router-dom";
import { phoneValidator, emailValidator } from "../../../wasfaty/Helper/utils";
import PhoneMask from "../../../forms/form-elements/input-mask/PhoneMask";
import { getUserData } from "@utils";
import DepartmentDetails from "../../Show/DepartmentDetails";

const CategoryList = [
  { value: "PMO", label: "PMO" },
  { value: "Operational", label: "Operational" },
  { value: "None", label: "None" },
];

const DeparmentForm = observer(() => {
  const navigate = useNavigate();
  const intl = useIntl();
  const authUser = getUserData();
  const isAdmin = authUser?.type === "admin";
  const isNupcoAdmin = authUser?.type === "nupco_admin";

  const [user, setUser] = useState(() => new DepartmentModal());

  // Supportive states
  const [reagions, setReagions] = useState([]);
  const [modules, setModules] = useState([]);
  const [sites, setSites] = useState([]);
  const { id } = useParams();
  let isEdit = !!id;

  useEffect(() => {
    init();
  }, [id]);

  const init = async () => {
    if (isEdit) {
      let res = await DepartmentService.show(id + "?department717=true");
      let _user = new DepartmentModal(res.data.data);
      _user.isEdit = true;
      setUser(_user);
    }

    // RegionService.getAllRegions().then((res) => {
    //   const data = get(res, "data.data", []);
    //   if (data.length) {
    //     setReagions(
    //       orderBy(
    //         data.map((item) => ({
    //           ...item,
    //           value: item._id,
    //           label: item.name,
    //         })),
    //         (region) => {
    //           return region.name.length;
    //         }
    //       )
    //     );
    //   }
    // });

    ModuleService.getAll().then((res) => {
      const data = get(res, "data.data", []);
      if (data.length) {
        setModules(
          data.map((item) => ({
            value: item._id,
            label: IntlService.m(item.name),
          }))
        );
      }
    });
  };

  useEffect(() => {
    // // Find all sites to present in the dropdown menu
    let regionIds = user.region_id?.map?.((r) => r._id);
    let allSitesPromise = [];
    if (regionIds?.length) {
      user.setAttribute("isSitesLoading", true);
      regionIds.forEach((regionId) => {
        allSitesPromise.push(SiteService.getAllSitesByRegion(regionId));
      });

      Promise.all(allSitesPromise)
        .then((allsites) => {
          allsites.forEach((res) => {
            let _sites = get(res, "data.data.site", []);
            setSites((s) =>
              uniqBy(
                uniqBy(
                  [
                    ...s.filter((site) => regionIds.includes(site.region_id)),
                    ..._sites.map((item) => ({
                      ...item,
                      label: item.name,
                      value: item._id,
                    })),
                  ],
                  "_id"
                ),
                "name"
              )
            );
          });
          user.setAttribute("isSitesLoading", false);
        })
        .catch((e) => {
          user.setAttribute("isSitesLoading", false);
        });
    }
  }, [user?.region_id]);

  // let name, value;
  const handleChange = (name, value) => {
    user.setAttribute(name, value);
  };

  const submit = (e) => {
    e.preventDefault();
    if (!user.isValid(isAdmin)) {
      user.setValidation(true);
      toast.error(
        intl.formatMessage({
          id: "Please fill all the required fields!",
          defaultMessage: "Please fill all the required fields!",
        })
      );
    } else {
      let payload = user.getServerPayload();
      payload.isNupcoUser =
        isNupcoAdmin ||
        payload.type === "nupco_admin" ||
        payload.type === "facility_manager";
      user.setIsSubmit(true);

      let servicePromise = null;
      if (user.isEdit) {
        servicePromise = DepartmentService.update(user._id, payload);
      } else {
        servicePromise = DepartmentService.create(payload);
      }
      servicePromise
        .then((res) => {
          if (res.status === 200 && res.data) {
            !user.isEdit && setUser(new DepartmentModal());
            toast.success(res.data.data);

            navigate("/Departments");
          } else {
            throw res;
          }
          user.setIsSubmit(false);
        })
        .catch((e) => {
          user.setIsSubmit(false);
          try {
            let errors = get(e, "response.data.data");
            if (errors) {
              toast.error(errors);
            } else {
              let errors = get(e, "response.data.errors");
              if (typeof errors === "object") {
                Object.entries(errors).forEach(([key, value]) => {
                  toast.error(value[0]);
                });
              }
            }
          } catch (error) {
            toast.error("Something went wrong!");
          }
        });

      //api call
    }
  };

  const getTranslation = (text) => {
    return intl.formatMessage({
      id: text,
      defaultMessage: text,
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4 shadow-none">
            <CardBody>
              <Form autoComplete="off">
                {!user.isReview ? (
                  <Row>
                    <Col md="12" sm="12" className="mb-1">
                      <Label className="form-label" for="nameMulti">
                        <FormattedMessage
                          id="Department Name"
                          defaultMessage={"Department Name"}
                        />{" "}
                        *
                      </Label>
                      <Input
                        value={user.name}
                        type="text"
                        name="name"
                        id="nameMulti"
                        placeholder={intl.formatMessage({
                          id: "Name",
                          defaultMessage: "Name",
                        })}
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      <Input
                        type="text"
                        hidden
                        invalid={!user.name && user.isValidation}
                      />
                      <FormFeedback>
                        <FormattedMessage
                          id={`"This field is required!"`}
                          defaultMessage={`"This field is required!"`}
                        />
                      </FormFeedback>
                    </Col>
                    {/* <Col md="6" sm="12" className="mb-1">
                      <Label className="form-label" for="lastNameMulti">
                        <FormattedMessage id="Email" defaultMessage={"Email"} />{" "}
                        *
                      </Label>
                      <Input
                        disabled={isEdit}
                        value={user.email}
                        type="text"
                        placeholder="email@email.com"
                        onChange={(e) => handleChange("email", e.target.value)}
                      />
                      <Input
                        type="text"
                        hidden
                        invalid={
                          !emailValidator(user.email) && user.isValidation
                        }
                      />
                      <FormFeedback>
                        {!user.email
                          ? `"This field is required!"`
                          : `Email address is not valid!`}
                      </FormFeedback>
                    </Col> */}
                    {/* <Col md="6" sm="12" className="mb-1">
                      <Label className="form-label" for="PasswordMulti">
                        <FormattedMessage
                          id="Password"
                          defaultMessage={"Password"}
                        />
                        *
                      </Label>
                      <Input
                        autoComplete="new-password"
                        value={user.password}
                        type="Password"
                        placeholder={intl.formatMessage({
                          id: "Password",
                          defaultMessage: "Password",
                        })}
                        onChange={(e) =>
                          handleChange("password", e.target.value)
                        }
                      />
                      <Input
                        type="text"
                        hidden
                        invalid={
                          !user.password && user.isValidation && !user.isEdit
                        }
                      />
                      <FormFeedback>
                        <FormattedMessage
                          id={`"This field is required!"`}
                          defaultMessage={`"This field is required!"`}
                        />
                      </FormFeedback>
                    </Col> */}

                    {/* <Col md="6" sm="12" className="mb-1">
                      <Label className="form-label" for="EmailMulti">
                        <FormattedMessage id="Phone" defaultMessage={"Phone"} />{" "}
                        *
                      </Label>

                      <PhoneMask
                        value={user.phone}
                        onChange={(e) => {
                          handleChange("phone", e.target.value);
                        }}
                      />
                      <Input
                        type="text"
                        hidden
                        invalid={
                          !phoneValidator(user.phone) && user.isValidation
                        }
                      />
                      <FormFeedback>
                        {" "}
                        <FormattedMessage
                          id={`Invalid phone number`}
                          defaultMessage={`Invalid phone number`}
                        />
                      </FormFeedback>
                    </Col> */}
                    {/* {user.isRegionRequired && (
                      <Col className="mb-1" md="6" sm="12">
                        <Label className="form-label">
                          <FormattedMessage
                            id="Regions"
                            defaultMessage={"Regions"}
                          />
                          {user.isRegionRequired && "*"}
                        </Label>
                        <Select
                          maxMenuHeight={200}
                          name="region_id"
                          onChange={(e) => {
                            let allRegionsIds = e.map((i) => i._id);
                            setSites((s) => [
                              ...s.filter((site) =>
                                allRegionsIds.includes(site.region_id)
                              ),
                            ]);

                            handleChange(
                              "site_id",
                              user.site_id.filter((s) =>
                                allRegionsIds.includes(s.region_id)
                              )
                            );
                            handleChange(
                              "cluster_id",
                              user.cluster_id.filter((s) =>
                                allRegionsIds.includes(s.region_id)
                              )
                            );
                            handleChange("region_id", e);
                          }}
                          isClearable={false}
                          theme={selectThemeColors}
                          value={user.region_id}
                          isMulti
                          // options={curtomop}
                          className="react-select"
                          classNamePrefix="select"
                          options={reagions}
                          placeholder={intl.formatMessage({
                            id: "Select",
                            defaultMessage: "Select...",
                          })}
                        />
                        <>
                          <Input
                            type="text"
                            hidden
                            invalid={
                              !user.region_id?.length && user.isValidation
                            }
                          />
                          <FormFeedback>
                            <FormattedMessage
                              id={`"This field is required!"`}
                              defaultMessage={`"This field is required!"`}
                            />
                          </FormFeedback>
                        </>
                      </Col>
                    )} */}

                    {/* {user.isSiteRequired && (
                      <Col className="mb-1" md="6" sm="12">
                        <Label className="form-label">
                          <FormattedMessage id="Site" defaultMessage={"Site"} />{" "}
                          {user.isSiteRequired && "*"}
                        </Label>
                        <Select
                          name="site_id"
                          maxMenuHeight={200}
                          value={user.site_id}
                          onChange={(e) => {
                            handleChange("site_id", e);
                          }}
                          isClearable={false}
                          theme={selectThemeColors}
                          options={sites}
                          isMulti
                          className="react-select"
                          classNamePrefix="select"
                          placeholder={intl.formatMessage({
                            id: user.isSitesLoading ? "Loading..." : "Select",
                            defaultMessage: user.isSitesLoading
                              ? "Loading..."
                              : "Select...",
                          })}
                        />
                        {user.isSiteRequired && (
                          <>
                            <Input
                              type="text"
                              hidden
                              invalid={
                                !user.site_id?.length && user.isValidation
                              }
                            />
                            <FormFeedback>
                              <FormattedMessage
                                id={`"This field is required!"`}
                                defaultMessage={`"This field is required!"`}
                              />
                            </FormFeedback>
                          </>
                        )}
                      </Col>
                    )} */}
                  </Row>
                ) : (
                  <DepartmentDetails user={user.getReview()} />
                )}

                <Row>
                  <Col sm="12">
                    <div className="d-flex justify-content-end">
                      {user.isReview ? (
                        <Row>
                          <Col md="5">
                            <Button.Ripple
                              style={{
                                backgroundColor: "#B6A884",
                                color: "black",
                              }}
                              className="round btun"
                              onClick={(e) => {
                                e.preventDefault();
                                user.setIsReview(false);
                              }}
                              color="primary"
                            >
                              <span style={{ color: "white" }}>
                                <FormattedMessage
                                  id="Back"
                                  defaultMessage={"Back"}
                                />
                              </span>
                            </Button.Ripple>
                          </Col>
                          <Col>
                            <Button.Ripple
                              style={{
                                backgroundColor: "#B6A884",
                                color: "black",
                                marginLeft: 10,
                                marginRight: 10,
                              }}
                              className="round btun"
                              type="submit"
                              onClick={submit}
                              color="primary"
                              disabled={user.isSubmit}
                            >
                              {user.isSubmit && (
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              {"  "}
                              <span style={{ color: "white" }}>
                                <FormattedMessage
                                  id={user.isEdit ? "Update" : "Submit"}
                                  defaultMessage={
                                    user.isEdit ? "Update" : "Submit"
                                  }
                                />
                              </span>
                            </Button.Ripple>
                          </Col>
                        </Row>
                      ) : (
                        <Button.Ripple
                          style={{ backgroundColor: "#B6A884", color: "black" }}
                          className="round btun"
                          onClick={(e) => {
                            e.preventDefault();
                            if (user.isValid()) {
                              user.setIsReview(true);
                            } else {
                              user.setValidation(true);
                              toast.error(
                                intl.formatMessage({
                                  id: "Please fill all the required fields!",
                                  defaultMessage:
                                    "Please fill all the required fields!",
                                })
                              );
                            }
                          }}
                          color="primary"
                        >
                          <span style={{ color: "white" }}>
                            <FormattedMessage
                              id="Review"
                              defaultMessage={"Review"}
                            />
                          </span>
                        </Button.Ripple>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
});

export default DeparmentForm;
