import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Row, Col, Card, CardBody } from "reactstrap";
import { DepartmentService } from "../../wasfaty/services";

import User from "../../../assets/images/user.png";
import { FormattedMessage, useIntl } from "react-intl";
import { getUserData } from "@utils";

import "./style.scss";
import { DepartmentModal } from "../../wasfaty/Models/DepartmentModal";

function DepartmentDetails({ user }) {
  const [item, setItem] = useState(user);
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const intl = useIntl();
  const authUser = getUserData();
  const isAdmin = authUser?.type === "admin";

  useEffect(() => {
    if (id && !user) {
      DepartmentService.show(id + "?department717=true").then((res) => {
        let m = new DepartmentModal(res.data?.data);
        setItem(m.getReview());
        setLoading(false);
      });
    }
  }, [id]);

  if (!item) return null;
  return (
    <>
      <Row id="user-details">
        <Col>
          <Card className="mb-4 shadow-none">
            <nav
              className="navbar navbar-expand-md "
              style={{ backgroundColor: "#04244A", color: "white" }}
            >
              <div className="container-fluid">
                <Link
                  className="navbar-brand"
                  to="#"
                  style={{ color: "white", fontWeight: "500" }}
                >
                  <FormattedMessage
                    id="Department Details"
                    defaultMessage="Department Details"
                  />
                </Link>
              </div>
            </nav>
            <CardBody className="card_body">
              <div className="main_card">
                {!isLoading ? (
                  <Row>
                    <Col md="3" sm="12">
                      <img src={User} className="img-fluid" alt="" />
                    </Col>
                    <Col md="9" sm="12">
                      <ItemRow textFor="Name" text={item.name} />
                      {/* <ItemRow textFor="Email" text={item.email} />
                      <ItemRow
                        textFor="Phone No#"
                        text={item.phone}
                        isLtr={true}
                      /> */}
                    </Col>
                  </Row>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DepartmentDetails;

const ItemRow = ({ textFor = "", text = "", isLtr = false }) => {
  return (
    <Row>
      <Col md="3">
        <p className="heading">
          <FormattedMessage id={textFor} defaultMessage={textFor} /> {" : "}
        </p>
      </Col>
      <Col style={{ padding: 0 }} {...(isLtr && { dir: "ltr" })}>
        <span className="item-value">
          {text && text?.length ? text : "N/A"}
        </span>
      </Col>
    </Row>
  );
};
