import { Singleton } from "../Resource";
import get from "lodash/get";
import { getUserData } from "@utils";
export const getRoleLabel = (role) => {
  switch (role) {
    case "agent":
      return "Reporter";
    case "regional representatives":
      return "Notifier";
    default:
      return role;
  }
};
const roles = [
  { id: 1, value: "admin", label: getRoleLabel("admin") },
  {
    id: 2,
    value: "regional representatives",
    label: getRoleLabel("regional representatives"),
  },
  { id: 3, value: "agent", label: getRoleLabel("agent") },
  { id: 4, value: "HQ_PHOC", label: "HQ–PHOC" },
  { id: 6, value: "HQ_DEPARTMENT", label: "HQ–Department" },
  { id: 7, value: "HQ_AUDIT", label: "HQ–Audit" },
  { id: 8, value: "REGION_PHOC", label: "Region–PHOC" },
  { id: 9, value: "REGION_DEPARTMENT", label: "Region–Department" },
  { id: 10, value: "REGION_AUDIT", label: "Region–Audit" },
];

class AuthClass {
  unprotectedModule = [];
  roles = roles;
  getRoleLabel = getRoleLabel;
  constructor() {}

  get user() {
    return getUserData();
  }

  get role() {
    return get(this.user, "type")?.toLowerCase();
  }

  get currentRoleLevel() {
    return get(
      this.roles.filter((role) => role.value === this.role),
      "0.id"
    );
  }

  get isAdmin() {
    return this.role === "admin";
  }

  get isNupcoAdmin() {
    return this.role === "nupco_admin";
  }

  get isRegionalRepresentatives() {
    // return true
    return this.role === "regional representatives";
  }

  get isAgent() {
    // return true;
    return this.role === "agent";
  }

  get isModerator() {
    return this.role === "moderator";
  }
  get isPharmacist() {
    return this.role === "pharmacist";
  }
  get isHQPHOC() {
    return this.role === "HQ_PHOC".toLowerCase();
  }
  get isHQDepartment() {
    return this.role === "HQ_DEPARTMENT".toLowerCase();
  }
  get isHQAudit() {
    return this.role === "HQ_AUDIT".toLowerCase();
  }
  get isRegionPHOC() {
    return this.role === "REGION_PHOC".toLowerCase();
  }
  get isRegionDepartment() {
    return this.role === "REGION_DEPARTMENT".toLowerCase();
  }
  get isRegionAudit() {
    return this.role === "REGION_AUDIT".toLowerCase();
  }
  get userModules() {
    const user = getUserData();

    // hajj insspection form for every one
    if (Array.isArray(user?.modules)) {
      [
        "Medical-Mission",
        "Hajj-PHC",
        "Pharmacies-Audit",
        "Hajj-General-Hospital",
        "Hajj-General-Complex",
        "Hajj-Private-Hospital",
      ].forEach((slug) => {
        user.modules.push({ slug });
      });
    }

    return get(user, "modules", []).map(
      (m) => m.slug?.toLowerCase() || m.name?.toLowerCase()
    );
  }

  get availableModules() {
    return [...this.userModules, ...this.unprotectedModule];
  }

  get regions() {
    const user = getUserData();
    return get(user, "region", []).map((m) => {
      return {
        ...m,
        label: m.name,
        value: m._id,
      };
    });
  }

  get isSystemUser() {
    return (
      this.user?.email === "danial.ghazali@ascend.com.sa" ||
      this.user?.email === "muhammad.ilyas@ascend.com.sa"
    );
  }
}
const AuthUser = new AuthClass();

export default AuthUser;
export { AuthClass };
