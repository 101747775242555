import {
  Users,
  Settings,
  Forms,
  HospitalIcon,
  DiseaseIcon,
  HealthcareIcon,
  EventIcon,
  RouteIcon,
  RolesIcon,
  ResponsibleDepartmentIcon,
  NotificationIcon,
  DashboardIcon,
} from "../../assets/wasfaty/SVG";

const navigationLinks = [
  // {
  //   id: "v-sight",
  //   slug: "Virtual-Sessions",
  //   title: "Virtual Sessions",
  //   icon: () => VirtualInspection,
  //   navLink: "/Virtual-Sessions",
  //   onlyFor: ["admin", "regional representatives", "agent"],
  // },
  // %template%
  {
    id: "dashboard",
    slug: "dashboard",
    title: "Dashboard",
    navLink: "/Dashboard",
    // permissions: [
    //   "can-create-module",
    //   "can-view-module",
    //   "can-update-module",
    //   "can-delete-module",
    // ],
    icon: () => DashboardIcon,
  },
  {
    id: "forms",
    slug: "Forms",
    title: "Forms",
    icon: () => Forms,

    children: [
      {
        id: "outbreak_717",
        slug: "Outbreak-717",
        title: "Outbreak Information Form",
        icon: () => EventIcon,
        permissions: [
          "can-create-outbreak-form",
          "can-view-outbreak-form",
          "can-update-outbreak-form",
          "can-delete-outbreak-form",
        ],
        navLink: "/Outbreak-717",
      },
      {
        id: "multi-sites-outbreak",
        slug: "Multi-Site-Events",
        title: "Multi-Site Events",
        icon: () => RouteIcon,
        permissions: [
          "can-create-multi-site-outbreak",
          "can-view-multi-site-outbreak",
          "can-update-multi-site-outbreak",
          "can-delete-multi-site-outbreak",
        ],
        navLink: "/multi-sites-outbreak",
      },
    ],
  },
  {
    id: "settings",
    slug: "Settings",
    title: "Settings",
    icon: () => Settings,

    children: [
      {
        id: "Users",
        slug: "Users",
        title: "Users",
        text: "User",
        navLink: "/Users",
        permissions: [
          "can-create-user",
          "can-view-user",
          "can-update-user",
          "can-delete-user",
        ],
        icon: () => Users,
      },
      {
        id: "Roles",
        slug: "Roles",
        title: "Roles",
        text: "Role",
        permissions: [
          "can-create-role",
          "can-view-role",
          "can-update-role",
          "can-delete-role",
        ],
        navLink: "/Roles",
        icon: () => RolesIcon,
      },
      {
        id: "Departments",
        slug: "Departments",
        title: "Responsible Departments",
        text: "User",
        permissions: [
          "can-create-department",
          "can-view-department",
          "can-update-department",
          "can-delete-department",
        ],
        navLink: "/Departments",
        icon: () => ResponsibleDepartmentIcon,
      },
      {
        id: "Regions",
        slug: "Regions",
        title: "Health Directorate",
        permissions: [
          "can-create-region",
          "can-view-region",
          "can-update-region",
          "can-delete-region",
        ],
        icon: () => HealthcareIcon,
        navLink: "/Regions",
      },
      {
        id: "Diseases",
        slug: "Diseases",
        title: "Event Type",
        permissions: [
          "can-create-eventType",
          "can-view-eventType",
          "can-update-eventType",
          "can-delete-eventType",
        ],
        icon: () => DiseaseIcon,
        navLink: "/EventType",
      },

      {
        id: "Sites",
        slug: "Sites",
        title: "Health Facility",
        icon: () => HospitalIcon,
        permissions: [
          "can-create-site",
          "can-view-site",
          "can-update-site",
          "can-delete-site",
        ],
        navLink: "/Sites",
      },

      {
        id: "Notifications",
        slug: "Notifications",
        title: "Notifications",
        icon: () => NotificationIcon,
        permissions: [
          "can-create-notification",
          "can-view-notification",
          "can-update-notification",
          "can-delete-notification",
        ],
        navLink: "/Notifications",
      },

      {
        id: "Public-Events",
        slug: "Public-Events",
        title: "Public-Events",
        icon: () => HospitalIcon,
        permissions: [
          "can-create-publicEvent",
          "can-view-publicEvent",
          "can-update-publicEvent",
          "can-delete-publicEvent",
        ],
        navLink: "/Public-Events",
      },

      {
        id: "Event-Types",
        slug: "Event-Types",
        title: "Events",
        icon: () => HospitalIcon,
        permissions: [
          "can-create-event",
          "can-view-event",
          "can-update-event",
          "can-delete-event",
        ],
        navLink: "/Events",
      },

      // {
      //   id: "arabic",
      //   slug: "Translation",
      //   title: "Translation",
      //   icon: () => (
      //     <img
      //       src={TranslaionIcon}
      //       style={{ marginRight: 10, marginLeft: 10 }}
      //     />
      //   ),
      //   navLink: "/Translation",
      // },
    ],
  },
];

export default navigationLinks;
