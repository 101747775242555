import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  FormFeedback,
} from "reactstrap";
import errorHandler from "../../../components/wasfatyComp/Api/errorHandler";
import { SC } from "../../../components/wasfatyComp/Api/serverCall";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
import get from "lodash/get";

import { useParams, useNavigate } from "react-router-dom";
import DiseaseService from "../../../wasfaty/services/DiseaseService";

function Diseases() {
  const intl = useIntl();

  const [isSubmit, setIsSubmit] = useState(false);
  const [data, setData] = useState({
    disease_name_en: "",
    // phcdisease: false,
  });

  const handleChange = (disease_name_en, value) => {
    setData({ ...data, [disease_name_en]: value });
  };

  const navigate = useNavigate();
  const { id } = useParams();
  const isEdit = !!id;
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    if (id) {
      DiseaseService.show(id).then((r) => {
        setData({ ...r.data?.data });
      });
    }
  }, [id]);
  const submit = (e) => {
    e.preventDefault();
    if (data.disease_name_en === "") {
      setValidation(true);
    } else {
      setValidation(false);
      setIsSubmit(true);

      let promise = null;
      if (isEdit) {
        promise = DiseaseService.update(data._id, {
          disease_name_en: data?.disease_name_en,
          disease_code: null,
          disease_name_ar: null,
          disease_longitude: null,
          disease_latitude: null,
        });
      } else {
        promise = DiseaseService.create({
          disease_name_en: data?.disease_name_en,
          disease_code: null,
          disease_name_ar: null,
          disease_longitude: null,
          disease_latitude: null,
        });
      }

      promise
        .then((res) => {
          if (res.status === 200 && res.data) {
            // toast.success(res.data.data);
            if (!isEdit) {
              setData({
                disease_name_en: "",
                moh: false,
              });
            }
            navigate("/EventType");
          } else {
            throw res;
          }
          setIsSubmit(false);
        })
        .catch((e) => {
          setIsSubmit(false);

          try {
            let errors = get(e, "response.data.errors");
            Object.entries(errors).forEach(([key, value]) => {
              toast.error(value[0]);
            });
          } catch (error) {
            toast.error("Something went wrong!");
          }
        });
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4 shadow-none">
            <CardBody>
              <Form>
                <Row>
                  <Col md="6" sm="12" className="mb-1">
                    <Label className="form-label" for="name">
                      <FormattedMessage id="Name" defaultMessage={"Name"} /> *
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      placeholder={intl.formatMessage({
                        id: "Name",
                        defaultMessage: "Name",
                      })}
                      value={data.disease_name_en}
                      onChange={(e) =>
                        handleChange("disease_name_en", e.target.value)
                      }
                      invalid={
                        data.disease_name_en === "" && validation ? true : false
                      }
                    />
                    <FormFeedback
                      invalid={
                        data.name === "" && validation ? "true" : "false"
                      }
                    >
                      <FormFeedback>
                        <FormattedMessage
                          id={`"This field is required!"`}
                          defaultMessage={`"This field is required!"`}
                        />
                      </FormFeedback>
                    </FormFeedback>
                    {/* <div className="d-flex flex-column mt-2">
                      <Label
                        for="switch-success"
                        className="form-check-label mb-50"
                      >
                        <FormattedMessage id="Moh" defaultMessage={"Moh"} />
                      </Label>
                      <div className="form-switch form-check-success">
                        <Input
                          type="switch"
                          name="phcRegion"
                          checked={data.phcRegion}
                          onChange={(e) =>
                            handleChange("phcRegion", e.target.checked)
                          }
                          invalid={
                            data.phcRegion === "" && validation ? true : false
                          }
                        />
                        <FormFeedback
                          invalid={
                            data.phcRegion === "" && validation
                              ? "true"
                              : "false"
                          }
                        >
                          <FormFeedback>
                            <FormattedMessage
                              id={`"This field is required!"`}
                              defaultMessage={`"This field is required!"`}
                            />
                          </FormFeedback>
                        </FormFeedback>
                      </div>
                    </div> */}
                  </Col>

                  <Col sm="12">
                    <div className="d-flex justify-content-end">
                      <Button.Ripple
                        disabled={isSubmit}
                        style={{ backgroundColor: "#B6A884", color: "black" }}
                        className="round btun"
                        type="submit"
                        onClick={submit}
                        color="primary"
                      >
                        {isSubmit && (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        <span style={{ color: "white" }}>
                          <FormattedMessage
                            id={isEdit ? "Update" : "Submit"}
                            defaultMessage={isEdit ? "Update" : "Submit"}
                          />{" "}
                        </span>
                      </Button.Ripple>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default Diseases;
