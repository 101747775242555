import { Singleton, Resources } from "../Resource";
import { SC } from "../Api/serverCall";
import startCase from "lodash/startCase";
import get from "lodash/get";
import moment from "moment";
class Service extends Resources {
  route = "sites";
  routes = {
    find: "?isPagination=1",
    create: "/create",
    show: "/show",
    update: "/update",
    delete: "/delete",
    export: "sites",
  };

  constructor() {
    super(arguments);
  }

  getHeaders(obj) {
    if (obj) {
      return [
        { title: "Region", dataIndex: "region" },
        {
          title: startCase("name"),
          dataIndex: "name",
        },

        { title: "Created At", dataIndex: "created_at" },
      ];
    }

    return [];
  }



  mapExportData(data = []) {
    try {
      data = data.map((item) => {
        item.region = get(item, "region.name", "");
        item.created_at = moment(item.created_at).format("DD-MM-YYYY h:mm A");
        return item;
      });
    } catch (error) {
      console.log("export error: ", error);
    }

    return {
      headers: this.getHeaders(data[0]),
      data,
    };
  }
}

export default Singleton(Service);
